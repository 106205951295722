import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { Form } from "react-bootstrap";
import Services from "../services/services";
import { Modal, Form as FormAntd, Input, Button, Row, Col, Divider } from 'antd';
import 'antd/dist/antd.css';
import useCollapse from 'react-collapsed';
import 'font-awesome/css/font-awesome.min.css';
import ImageCapture from 'react-image-data-capture';
import SignatureCanvas from 'react-signature-canvas'
import { IdleTimerProvider, useIdleTimerContext } from 'react-idle-timer';
import { ReloadOutlined } from '@ant-design/icons';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import moment from 'moment';

import "./Lift.css";

let photoTypes = [
    {
        id: 1,
        text: "Equipment",
        photo: ""
    },
    {
        id: 2,
        text: "Truck",
        photo: ""
    },
    {
        id: 3,
        text: "Temperature settings",
        photo: ""
    },
    {
        id: 4,
        text: "Attached genset",
        photo: ""
    },
    {
        id: 5,
        text: "Humidity",
        photo: ""
    },
    {
        id: 6,
        text: "Vent settings",
        photo: ""
    }
]

const initialState = {
    terminals: [],
    interchanges: [],
    filteredInterchanges: [],
    currentUserTerminal: null,
    loading: true,
};

const filterInitialState = {
    terminal: "ALL",
    status: 0,
    equipment: "BOTH",
    search: "",
    lastUpdatedPropName: "",
}

const modalInitialState = {
    visible: false,
    bookingNumber: '',
    bookingComments: '',
    equipment: null,
    errorEquipment: null,
    extraInfo: null,
    ioutNumber: null,
    fromLocation: '',
    toLocation: '',
    isValid: false,
    genset: null,
    errorGenset: null,
    attached: null,
    hasWarning: false,
    attachGenset: false,
    loaded: false,
    ventInfo: null,
    bookHumidity: null
}

const modalInitialState2 = {
    visible: false,
    visibleStep0: false,
    visibleStep1: false,
    visibleStep2: false,
    isValid: false,
    signature: null,
    imgFile: null,
    imgSrc: null,
    interchangeNumber: null,
    equipmentPhoto: null,
    equip: null,
    photoTypeId: 1,
    photos: photoTypes
}

const preAssignModalInitialState = {
    visible: false,
    bookingPreassignedItems: []
}

const iOutStatus = {
    WaitingApproval: 1,
    Completed: 2,
}



export const Lift = () => {
    const [state, setState] = useState(initialState);
    const [refresh, setRefresh] = useState(Date.now());
    const [findEquipment, setFindEquipment] = useState('');
    const [findGenset, setFindGenset] = useState('');
    const [filterState, setFilterState] = useState(filterInitialState);
    const [modalState, setModalState] = useState(modalInitialState);
    const [modalState2, setModalState2] = useState(modalInitialState2);
    const [preAssignModal, setPreAssignModal] = useState(preAssignModalInitialState);
    const [firstLoad, setFirstLoad] = useState(true);
    const [readyToRelease, setReadyToRelease] = useState(false);

    const [takePhoto1, setTakePhoto1] = useState(false);
    const [takePhoto2, setTakePhoto2] = useState(false);
    const [takePhoto3, setTakePhoto3] = useState(false);
    const [takePhoto4, setTakePhoto4] = useState(false);
    const [takePhoto5, setTakePhoto5] = useState(false);
    const [takePhoto6, setTakePhoto6] = useState(false);

    const currentFilter = useRef(null);
    currentFilter.current = filterState;

    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
    let sigRef = {};

    const onIdleTimerProviderPrompt = () => {
    }

    const onIdleTimerProviderActive = (event) => {
    }

    const onIdleTimerProviderAction = (event) => {
    }

    const onIdleTimerProviderIdle = () => {
        //console.log('idle for one minute...');
        //window.location.reload();
        setRefresh(Date.now());
    }

    const conditioner = (item, prefix, postfix) => {
        return item ? (prefix ? prefix : "") + item + (postfix ? postfix : "") : "";
    }


    const [pendingEquipment, setPendingEquipment] = useState(null);
    useEffect(() => {
        if (modalState.visible && pendingEquipment) {
            setFindEquipment(pendingEquipment);
        }
    }, [modalState.visible, pendingEquipment]);
    useEffect(() => {
        if (modalState.visible && pendingEquipment) {
            handleFindEquipment();
            setPendingEquipment(null);
        }
    }, [findEquipment]);

    const renderActiveInterchangeTable = () => {
        return (
            <>
                <IdleTimerProvider
                    timeout={1000 * 60}
                    onPrompt={onIdleTimerProviderPrompt}
                    onIdle={onIdleTimerProviderIdle}
                    onActive={onIdleTimerProviderActive}
                    onAction={onIdleTimerProviderAction} />

                <Modal
                    visible={preAssignModal.visible}
                    onCancel={() => {
                        setPreAssignModal(preAssignModalInitialState);
                        setModalState({ ...modalState, visible: true });
                    }}
                    footer={[
                        <Button
                            key="cancel"
                            shape="round"
                            onClick={() => {
                                setPreAssignModal(preAssignModalInitialState);
                                setModalState({ ...modalState, visible: true });
                            }}>
                            Cancel
                        </Button>
                    ]}
                    width={450}
                    destroyOnClose={true}
                    closable={false}>
                    <div style={{ textTransform: 'uppercase', fontSize: '1.2em' }}>
                        <div className="text-center mb-4">
                            <h5>Select Preassigned Equipment</h5>
                        </div>
                        {preAssignModal.bookingPreassignedItems.map((item, index) => (
                            <Button
                                key={index}
                                block
                                className="mb-2"
                                style={{ textAlign: 'left', height: 'auto', padding: '12px' }}
                                onClick={() => {
                                    setPreAssignModal(preAssignModalInitialState);
                                    setModalState({ ...modalState, visible: true });
                                    setPendingEquipment(item);
                                }}>
                                {item}
                            </Button>
                        ))}
                    </div>
                </Modal>
                <Modal style={{ textTransform: 'uppercase', fontSize: '3em' }} width={450} visible={modalState.visible} okText="Release" okButtonProps={{ shape: "round", disabled: !modalState.isValid || (modalState.attachGenset && !modalState.genset) }} cancelButtonProps={{ shape: "round" }} onCancel={handleCancelModal} onOk={handleOnCreateModal} destroyOnClose={true} closable={false}>
                    <FormAntd layout="vertical" preserve={false}>
                        <Row>
                            <Col style={{ fontSize: '13px', position: 'absolute', right: '32px', textAlign: 'right' }} span={24}>
                                <span className="fw-bold">INTERCHANGE #: {modalState.ioutNumber}</span>
                                <br />
                                <span className="fw-bold">BOOKING #: {modalState.bookingNumber}</span>
                                <br />
                                <span className="fw-bold">FROM {modalState.fromLocation ? modalState.fromLocation : "..."} TO {modalState.toLocation ? modalState.toLocation : "..."}</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ marginTop: '35px' }} span={24}>
                                <div>
                                    <label style={{ fontSize: '20px' }}>Equipment Code</label>
                                    <Input.Search
                                        autoFocus={true}
                                        name="findEquipment"
                                        allowClear
                                        onBlur={handleFindEquipment}
                                        value={findEquipment}
                                        onSearch={handleFindEquipment}
                                        onChange={i => setFindEquipment(i.target.value)}
                                        status={modalState.errorEquipment !== null ? 'error' : (modalState.hasWarning ? 'warning' : 'success')}
                                    />
                                    {modalState.errorEquipment && <div style={{ color: 'red', fontSize: '14px' }}>{modalState.errorEquipment}</div>}
                                    {modalState.extraInfo && <div style={{ color: 'gray', fontSize: '14px' }}>{modalState.extraInfo}</div>}
                                </div>
                                {/*<FormAntd.Item autoFocus={true} label="Equipment Code" name="Equipment" validateStatus={modalState.errorEquipment !== null ? 'error' : (modalState.hasWarning ? 'warning' : 'success')} help={modalState.errorEquipment} extra={modalState.extraInfo} >*/}
                                {/*    <Input.Search autoFocus={true} name="findEquipment" allowClear onBlur={handleFindEquipment} value={findEquipment} onSearch={handleFindEquipment} onChange={i => setFindEquipment(i.target.value)} />*/}
                                {/*</FormAntd.Item>*/}
                            </Col>
                        </Row>
                        <Row gutter={[16, 48]} className="text-center">
                            <Col span={10}><span className="fw-bold">{conditioner(modalState.equipment?.size) + conditioner(modalState.equipment?.type) + conditioner(modalState.equipment?.height) + conditioner(modalState.equipment?.isoCode, " - ")}</span></Col>
                            <Col span={14}><span className="fw-bold">{conditioner(modalState.equipment?.daysIn, "Days IN: ", " - ") + conditioner(modalState.equipment?.terminal) + conditioner(modalState.equipment?.location, " - ")}</span></Col>
                        </Row>
                        <Divider />
                        <Row gutter={[16, 48]}>
                            <Col>Booking Comments:
                                <span className="fw-bold">{modalState.bookingComments}</span>
                                <br />
                                <span style={{ color: 'darkred', fontWeight: 'bold' }}>{modalState.loaded ? "LOADED" : "EMPTY"}</span>
                            </Col>
                        </Row>
                        {
                            (modalState.bookHumidity || (modalState.ventInfo && modalState.ventInfo.set !== "CLOSED")) &&
                            <>
                                <Divider />
                                {
                                    modalState.bookHumidity &&
                                    <>Dehumidification: <span className="fw-bold">{modalState.bookHumidity}</span><br></br></>
                                }
                                {
                                    modalState.ventInfo && modalState.ventInfo.set !== "CLOSED" &&
                                    <>Vent Setting: <span className="fw-bold">{modalState.ventInfo.value}</span></>
                                }
                            </>
                        }
                        <Divider />
                        <Row gutter={[16, 48]}>
                            <Col span={16}>
                                <FormAntd.Item label="Genset #" name="Genset" validateStatus={(modalState.errorGenset !== null || (modalState.attachGenset && !modalState.genset)) ? 'error' : (modalState.hasWarning ? 'warning' : 'success')} help={modalState.errorGenset}>
                                    <Input.Search name="findGenset" disabled={!modalState.attachGenset} allowClear onBlur={handleFindGenset} value={findGenset} onSearch={handleFindGenset} onChange={i => setFindGenset(i.target.value)} />
                                </FormAntd.Item>
                            </Col>
                            <Col span={1}></Col>
                            <Col span={7} style={{ paddingTop: '0.4em' }}>Type: <span className="fw-bold">{modalState.genset?.type}</span></Col>
                        </Row>
                    </FormAntd>
                </Modal>
                <Modal style={{ fontSize: '3em' }} title={modalState2.visibleStep0 ? "Please confirm" : ""} width={750} visible={modalState2.visible}
                    okText={modalState2.visibleStep0 ? "Confirm" : (modalState2.visibleStep1 ? "Next" : "Finish")}
                    okButtonProps={{ shape: "round", disabled: !modalState2.visibleStep0 && !modalState2.isValid }} cancelButtonProps={{ shape: "round" }}
                    onCancel={handleCancelModal2} onOk={handleNextStep} destroyOnClose={true} closable={false}>
                    <FormAntd layout="vertical">
                        {
                            modalState2.equip && modalState2.equip[0] && modalState2.visibleStep0 === true &&
                            <ul>
                                {modalState2.equip[0].bookingComments && modalState2.equip[0].bookingComments != null && modalState2.equip[0].bookingComments.trim() !== "" &&
                                    <li style={{ marginBottom: '0.7em' }}>
                                        Container adequate to booking comments?
                                        <br />
                                        <span style={{ marginLeft: '2em', fontStyle: 'italic', color: 'darkred' }}>
                                            {modalState2.equip[0].bookingComments.trim()}
                                        </span>
                                    </li>
                                }
                                <li>
                                    Check CONTAINER state: <span style={{ color: 'darkred', fontWeight: 'bold' }}>{modalState2.equip[0].loaded ? "LOADED" : "EMPTY"}</span>
                                </li>
                                {
                                    modalState2.equip[0].bodyDamaged === true &&
                                    <li>
                                        Check CONTAINER body: <span style={{ color: 'red', fontWeight: 'bold' }}>{modalState2.equip[0].bodyDamaged ? "BODY DAMAGED" : ""}</span>
                                    </li>
                                }
                            </ul>
                        }
                        {
                            modalState2.visibleStep1 === true &&
                            <>

                                <Row>
                                    {
                                        takePhoto1 === true &&
                                        <Col span={8}>
                                            <button onClick={() => { setTakePhoto1(false); }} style={{ width: '200px', paddingBottom: '5px' }}>Cancel photo taking</button>
                                            <div style={{ border: 'solid', borderWidth: '1px', height: 'auto', width: '200px', textAlign: 'center', }}>
                                                <ImageCapture
                                                    onCapture={onCapture}
                                                    onError={onError}
                                                    width={200}
                                                    height={200}
                                                    className='imgCapture'
                                                    userMediaConfig={config}
                                                />
                                            </div>
                                            <div style={{ width: '200px', fontWeight: 'bold', fontSize: '12px' }} className="btn" role="button">
                                                Equipment
                                            </div>
                                        </Col>
                                    }

                                    {
                                        takePhoto1 === false &&
                                        <Col span={8}>
                                            <button onClick={() => { setTakePhoto1(true); }} style={{ width: '200px', paddingBottom: '5px' }}>Take a photo</button>
                                            <div style={{ border: 'solid', borderWidth: '1px', borderColor: 'gray', height: '200px', width: '200px', inset: '10px' }}>
                                                <img id="imgPhoto" src={modalState2.photos[0].photo} alt="captured-img" style={{ width: '100%', height: '100%' }} />
                                            </div>
                                            <div style={{ width: '200px', fontWeight: 'bold', fontSize: '12px' }} className="btn" role="button">
                                                Equipment
                                            </div>

                                        </Col>
                                    }

                                    {
                                        takePhoto2 === true &&
                                        <Col span={8}>
                                            <button onClick={() => { setTakePhoto2(false); }} style={{ width: '200px', paddingBottom: '5px' }}>Cancel photo taking</button>
                                            <div style={{ border: 'solid', borderWidth: '1px', height: 'auto', width: '200px', textAlign: 'center', }}>
                                                <ImageCapture
                                                    onCapture={onCapture2}
                                                    onError={onError}
                                                    width={200}
                                                    height={200}
                                                    className='imgCapture'
                                                    userMediaConfig={config}
                                                />
                                            </div>
                                            <div style={{ width: '200px', fontWeight: 'bold', fontSize: '12px' }} className="btn" role="button">
                                                Truck
                                            </div>
                                        </Col>
                                    }

                                    {
                                        takePhoto2 === false &&
                                        <Col span={8}>
                                            <button onClick={() => { setTakePhoto2(true); }} style={{ width: '200px', paddingBottom: '5px' }}>Take a photo</button>
                                            <div style={{ border: 'solid', borderWidth: '1px', borderColor: 'gray', height: '200px', width: '200px', inset: '10px' }}>
                                                <img id="imgPhoto2" src={modalState2.photos[1].photo} alt="captured-img" style={{ width: '100%', height: '100%' }} />
                                            </div>
                                            <div style={{ width: '200px', fontWeight: 'bold', fontSize: '12px' }} className="btn" role="button">
                                                Truck
                                            </div>
                                        </Col>
                                    }

                                    {
                                        takePhoto3 === true &&
                                        <Col span={8}>
                                            <button onClick={() => { setTakePhoto3(false); }} style={{ width: '200px', paddingBottom: '5px' }}>Cancel photo taking</button>
                                            <div style={{ border: 'solid', borderWidth: '1px', height: 'auto', width: '200px', textAlign: 'center', }}>
                                                <ImageCapture
                                                    onCapture={onCapture3}
                                                    onError={onError}
                                                    width={200}
                                                    height={200}
                                                    className='imgCapture'
                                                    userMediaConfig={config}
                                                />
                                            </div>
                                            <div style={{ width: '200px', fontWeight: 'bold', fontSize: '12px' }} className="btn" role="button">
                                                Temperature settings
                                            </div>
                                        </Col>
                                    }

                                    {
                                        takePhoto3 === false &&
                                        <Col span={8}>
                                            <button onClick={() => { setTakePhoto3(true); }} style={{ width: '200px', paddingBottom: '5px' }}>Take a photo</button>
                                            <div style={{ border: 'solid', borderWidth: '1px', borderColor: 'gray', height: '200px', width: '200px', inset: '10px' }}>
                                                <img id="imgPhoto3" src={modalState2.photos[2].photo} alt="captured-img" style={{ width: '100%', height: '100%' }} />
                                            </div>
                                            <div style={{ width: '200px', fontWeight: 'bold', fontSize: '12px' }} className="btn" role="button">
                                                Temperature settings
                                            </div>
                                        </Col>
                                    }
                                </Row>
                                {
                                    modalState2.equip && (modalState2.equip[0]?.attached === "T" || modalState2.equip[0]?.bookHumidity || modalState2.equip[0]?.ventSet?.set === "OPEN") &&
                                    <Row>
                                        {
                                            modalState2.equip[0]?.attached === "T" &&
                                            <>
                                                {
                                                    takePhoto4 === true &&
                                                    <Col span={8}>
                                                        <button onClick={() => { setTakePhoto4(false); }} style={{ width: '200px', paddingBottom: '5px' }}>Cancel photo taking</button>
                                                        <div style={{ border: 'solid', borderWidth: '1px', height: 'auto', width: '200px', textAlign: 'center', }}>
                                                            <ImageCapture
                                                                onCapture={onCapture4}
                                                                onError={onError}
                                                                width={200}
                                                                height={200}
                                                                className='imgCapture'
                                                                userMediaConfig={config}
                                                            />
                                                        </div>
                                                        <div style={{ width: '200px', fontWeight: 'bold', fontSize: '12px' }} className="btn" role="button">
                                                            Attached genset
                                                        </div>
                                                    </Col>
                                                }

                                                {
                                                    takePhoto4 === false &&
                                                    <Col span={8}>
                                                        <button onClick={() => { setTakePhoto4(true); }} style={{ width: '200px', paddingBottom: '5px' }}>Take a photo</button>
                                                        <div style={{ border: 'solid', borderWidth: '1px', borderColor: 'gray', height: '200px', width: '200px', inset: '10px' }}>
                                                            <img id="imgPhoto4" src={modalState2.photos[3].photo} alt="captured-img" style={{ width: '100%', height: '100%' }} />
                                                        </div>
                                                        <div style={{ width: '200px', fontWeight: 'bold', fontSize: '12px' }} className="btn" role="button">
                                                            Attached genset
                                                        </div>
                                                    </Col>
                                                }
                                            </>
                                        }

                                        {
                                            modalState2.equip[0]?.bookHumidity &&
                                            <>
                                                {
                                                    takePhoto5 === true &&
                                                    <Col span={8}>
                                                        <button onClick={() => { setTakePhoto5(false); }} style={{ width: '200px', paddingBottom: '5px' }}>Cancel photo taking</button>
                                                        <div style={{ border: 'solid', borderWidth: '1px', height: 'auto', width: '200px', textAlign: 'center', }}>
                                                            <ImageCapture
                                                                onCapture={onCapture5}
                                                                onError={onError}
                                                                width={200}
                                                                height={200}
                                                                className='imgCapture'
                                                                userMediaConfig={config}
                                                            />
                                                        </div>
                                                        <div style={{ width: '200px', fontWeight: 'bold', fontSize: '12px' }} className="btn" role="button">
                                                            Humidity
                                                        </div>
                                                    </Col>
                                                }

                                                {
                                                    takePhoto5 === false &&
                                                    <Col span={8}>
                                                        <button onClick={() => { setTakePhoto5(true); }} style={{ width: '200px', paddingBottom: '5px' }}>Take a photo</button>
                                                        <div style={{ border: 'solid', borderWidth: '1px', borderColor: 'gray', height: '200px', width: '200px', inset: '10px' }}>
                                                            <img id="imgPhoto5" src={modalState2.photos[4].photo} alt="captured-img" style={{ width: '100%', height: '100%' }} />
                                                        </div>
                                                        <div style={{ width: '200px', fontWeight: 'bold', fontSize: '12px' }} className="btn" role="button">
                                                            Humidity
                                                        </div>
                                                    </Col>
                                                }
                                            </>
                                        }

                                        {
                                            modalState2.equip[0]?.ventSet?.set === "OPEN" &&
                                            <>
                                                {
                                                    takePhoto6 === true &&
                                                    <Col span={8}>
                                                        <button onClick={() => { setTakePhoto6(false); }} style={{ width: '200px', paddingBottom: '5px' }}>Cancel photo taking</button>
                                                        <div style={{ border: 'solid', borderWidth: '1px', height: 'auto', width: '200px', textAlign: 'center', }}>
                                                            <ImageCapture
                                                                onCapture={onCapture6}
                                                                onError={onError}
                                                                width={200}
                                                                height={200}
                                                                className='imgCapture'
                                                                userMediaConfig={config}
                                                            />
                                                        </div>
                                                        <div style={{ width: '200px', fontWeight: 'bold', fontSize: '12px' }} className="btn" role="button">
                                                            Vent settings
                                                        </div>
                                                    </Col>
                                                }

                                                {
                                                    takePhoto6 === false &&
                                                    <Col span={8}>
                                                        <button onClick={() => { setTakePhoto6(true); }} style={{ width: '200px', paddingBottom: '5px' }}>Take a photo</button>
                                                        <div style={{ border: 'solid', borderWidth: '1px', borderColor: 'gray', height: '200px', width: '200px', inset: '10px' }}>
                                                            <img id="imgPhoto6" src={modalState2.photos[5].photo} alt="captured-img" style={{ width: '100%', height: '100%' }} />
                                                        </div>
                                                        <div style={{ width: '200px', fontWeight: 'bold', fontSize: '12px' }} className="btn" role="button">
                                                            Vent settings
                                                        </div>
                                                    </Col>
                                                }
                                            </>
                                        }
                                    </Row>
                                }
                            </>

                        }
                        {
                            modalState2.visibleStep2 === true &&
                            <div>
                                <Row>
                                    <Col span={24}>
                                        <table style={{ fontSize: '11px' }}>
                                            <tr>
                                                <td className="fw-bold">TIR#</td>
                                                <td className="fw-bold">Equipment</td>
                                                <td className="fw-bold">Temp/Vent</td>
                                                <td className="fw-bold">Transport</td>
                                                <td className="fw-bold">Notes</td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '115px', verticalAlign: 'baseline' }} >
                                                    <span>{modalState2.equip[0].interchangeNumber.value}</span><br />
                                                    <span>{moment(modalState2.equip[0].logDateTime).format("YYYY-MM-DD HH:mm")}</span>
                                                </td>
                                                <td style={{ width: '150px', verticalAlign: 'baseline' }}>
                                                    <span>{modalState2.equip[0].client}<br></br></span>
                                                    <span>{modalState2.equip[0].equipment.value}<br></br></span>
                                                    {
                                                        (((modalState2.equip[0].status === 0) ? modalState2.equip[0].bLineIsoCode : modalState2.equip[0].maintIsoCode) !== "") && (((modalState2.equip[0].status === 0) ? modalState2.equip[0].bLineIsoCode : modalState2.equip[0].maintIsoCode) !== null) ?
                                                            <><span>{(modalState2.equip[0].status === 0) ? modalState2.equip[0].bLineIsoCode : modalState2.equip[0].maintIsoCode}</span><br /></> : <></>
                                                    }
                                                    {modalState2.equip[0].equipment.attNum === "" && modalState2.equip[0].status !== 0 ?
                                                        (<span>{modalState2.equip[0].equipment.equipNum}</span>) :
                                                        (<div><span>{modalState2.equip[0].equipment.equipNum + " / "}{modalState2.equip[0].equipment.attNum}</span></div>)
                                                    }
                                                </td>
                                                <td style={{ width: '100px', verticalAlign: 'baseline' }}>
                                                    <span>{modalState2.equip[0].temperature.value}</span><br />
                                                    <span>{"Vent " + modalState2.equip[0].ventSet.value}</span><br />
                                                    <span>{((modalState2.equip[0].bookHumidity !== null && modalState2.equip[0].bookHumidity !== "") ? "H" + modalState2.equip[0].bookHumidity : "") + ((modalState2.equip[0].bookAirExchange !== null && modalState2.equip[0].bookAirExchange !== "") ? " X" + modalState2.equip[0].bookAirExchange : "")}</span>
                                                </td>
                                                <td style={{ width: '150px', verticalAlign: 'baseline' }}>
                                                    <span>{modalState2.equip[0].shippingCompany}</span><br />
                                                    <span style={{ textDecoration: 'underline' }}>Booking#:</span><span> {modalState2.equip[0].bookingNumber}</span><br />
                                                    <span style={{ textDecoration: 'underline' }}>Truck#:</span><span> {modalState2.equip[0].truckNumber}</span><br />
                                                    <span style={{ textDecoration: 'underline' }}>Licence#:</span><span> {modalState2.equip[0].truckLicense}</span>
                                                </td>
                                                <td style={{ width: '200px', verticalAlign: 'baseline' }}>
                                                    <div style={{ width: '100%' }}>{modalState2.equip[0].bookingComments}</div>
                                                </td>
                                            </tr>
                                        </table>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <div style={{ paddingTop: '10px', paddingBottom: '3px', color: 'red', fontSize: '12px', fontWeight: 'bold', fontStyle: 'italic' }}>Disclaimer : Shipper is responsible of container set point</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <button onClick={() => handleBack()} className='primary' style={{ float: 'left', marginBottom: '5px' }}><i style={{ color: 'blue' }} className={'fa fa-arrow-left'}></i></button>

                                        <button onClick={() => handleClear()} className='primary' style={{ float: 'right', marginBottom: '5px' }}><i style={{ color: 'red' }} className={'fa fa-times'}></i></button>
                                        <SignatureCanvas penColor='green'
                                            ref={ref => {
                                                sigRef = ref;
                                            }}
                                            onEnd={() => {
                                                const imageData = sigRef
                                                    .toDataURL("image/png");

                                                setModalState2({ ...modalState2, signature: imageData, isValid: true });
                                            }}
                                            canvasProps={{ width: 700, height: 200, className: 'sigCanvas' }} />
                                    </Col>
                                </Row>
                            </div>
                        }
                    </FormAntd>
                </Modal>

                <div className="collapsible">
                    <div className="header" {...getToggleProps()}>
                        <div className="border border-5 border-secondary  overflow bg-secondary" style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                            <table className="" aria-labelledby="tabelLabel">
                                <thead className="bg-secondary">
                                    <tr>
                                        <th className="fs-4" colSpan="3" id="tabelLabel" style={{ color: "white" }}><i className={isExpanded ? 'fa fa-minus' : 'fa fa-plus'}></i> Active Interchange Out</th>
                                        <th className="text-end" style={{ color: "white" }}>Status&nbsp;</th>
                                        <th colSpan="3">
                                            <Form.Select
                                                value={filterState.status}
                                                className="fs-7 status-select fw-bold"
                                                size="sm"
                                                onClick={i => {
                                                    i.preventDefault();
                                                    i.stopPropagation();
                                                }}
                                                onChange={i => {
                                                    handleFilters(i, "status");
                                                }}>
                                                <option value="0">ACTIVE</option>
                                                <option value="1">Waiting approval</option>
                                            </Form.Select>
                                        </th>
                                        <th colSpan="3">
                                            <Form.Control
                                                className="fs-7"
                                                size="sm"
                                                type="text"
                                                name="search"
                                                value={filterState.search}
                                                onClick={i => {
                                                    i.preventDefault();
                                                    i.stopPropagation();
                                                }}
                                                onChange={i => {
                                                    handleFilters(i, "search");
                                                    i.preventDefault();
                                                    i.stopPropagation();
                                                }} />
                                        </th>
                                        <th>
                                            <Button
                                                style={{ display: 'inline-flex', alignItems: 'center' }}
                                                type="primary"
                                                className="rounded"
                                                icon={<ReloadOutlined />}
                                                onClick={(i) => {
                                                    i.preventDefault();
                                                    i.stopPropagation();
                                                    setRefresh(Date.now());
                                                }}>
                                                Refresh
                                            </Button>
                                        </th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>


                    <div {...getCollapseProps()}>
                        <div className="content">
                            {
                                !state.loading &&
                                <div style={{ paddingTop: '0px' }}>
                                    <div className="border border-5 border-secondary  overflow bg-secondary">
                                        <table className="" aria-labelledby="tabelLabel">
                                            <tbody>
                                                {state.filteredInterchanges.map((interchange) => (
                                                    <tr className="border border-5 border-secondary bg-white" key={interchange.pickupDateTime} onClick={i => handleLineClick(interchange.interchangeNumber.value, interchange.status, interchange.bookingComments, interchange.bookingNumber, interchange.attached, interchange.loaded, interchange.bookHumidity, interchange.ventSet, interchange.fromLocation, interchange.toLocation)}>
                                                        <td className="fs-7 cell-pad text-nowrap">
                                                            <span>{moment(interchange.logDateTime).format("YYYY-MM-DD HH:mm")}<br></br></span>
                                                            <span className={`fw-bold ${interchange.interchangeNumber.isRed ? "RED" : ""}`}>{interchange.interchangeNumber.value + " " + interchange.terminal}</span>
                                                        </td>
                                                        <td style={{ backgroundColor: interchange.color }} className={`fs-6 fw-bold text-center cell-pad`}>{interchange.client}</td>
                                                        {interchange.status === 0 ?
                                                            (
                                                                //<td className={`fs-6 fw-bold text-center cell-pad ${interchange.equipment.isGreen ? "bg-green" : ""}`}><span>{interchange.equipment.value}</span></td>
                                                                <td className={`fs-6 fw-bold text-center cell-pad`}>
                                                                    <span>{interchange.equipment.value}</span><br />
                                                                    <span>{interchange.bLineIsoCode}</span>
                                                                </td>
                                                            ) :
                                                            (
                                                                //  <td className={`text-center cell-pad ${interchange.equipment.isGreen ? "bg-green" : ""}`}>
                                                                <td className={`text-center cell-pad`}>
                                                                    <span className="fs-6 fw-bold">{interchange.equipment.value + (interchange.maintIsoCode !== "" && interchange.maintIsoCode !== null ? " - " + interchange.maintIsoCode : "")}</span>
                                                                    <br />
                                                                    {interchange.equipment.attNum === "" ?
                                                                        (<span className="fs-7 fw-bold">{interchange.equipment.equipNum}</span>) :
                                                                        (<div><span className="fs-7 fw-bold">{interchange.equipment.equipNum + " / "}{interchange.equipment.attNum}</span></div>)
                                                                    }
                                                                    <br />
                                                                    <span style={{ color: 'darkgoldenrod', fontWeight: 'bold' }}>{interchange.equipNotes}</span>
                                                                </td>
                                                            )}
                                                        {/*<td className={`fs-6 fw-bold text-center cell-pad ${interchange.comment.isGreen ? "bg-green" : ""}`}>{interchange.comment.value}</td>*/}
                                                        {/*<td className={`fs-6 fw-bold text-center cell-pad ${interchange.repo.isGreen ? "bg-green" : ""}`}>{interchange.repo.value}</td>*/}
                                                        {/*<td className={`fs-6 fw-bold text-center cell-pad ${interchange.temperature.isGreen ? "bg-green" : ""}`}>{interchange.temperature.value}</td>*/}
                                                        {/*<td className={`fs-6 fw-bold text-center cell-pad ${interchange.ventSet.isGreen ? "bg-green" : ""}`}>{"Vent " + interchange.ventSet.value}</td>*/}

                                                        <td className={`fs-6 fw-bold text-center cell-pad`}>{interchange.comment.value}</td>
                                                        <td className={`fs-6 fw-bold text-center cell-pad`}>{interchange.repo.value}</td>
                                                        <td className={`fs-6 fw-bold text-center cell-pad`}><span>{interchange.temperature.value}</span><br /><span style={{ color: interchange.loaded ? 'darkred' : 'black' }} >{interchange.loaded ? "LOADED" : "EMPTY"}</span></td>
                                                        <td className={`fs-6 fw-bold text-center cell-pad`}><span style={{ backgroundColor: interchange.ventSet?.set === "OPEN" ? 'yellow' : null }}>{interchange.ventSet.value}</span><br /><span style={{ backgroundColor: 'yellow' }}>{((interchange.bookHumidity != null && interchange.bookHumidity !== "") ? "Humidity " + interchange.bookHumidity : "") /*+ ((interchange.bookAirExchange != null && interchange.bookAirExchange != "") ? " X" + interchange.bookAirExchange : "")*/}</span></td>

                                                        {interchange.status === 0 ?
                                                            (
                                                                <>
                                                                    <td className="fs-6 fw-bold text-center cell-pad">{interchange.shippingCompany}</td>
                                                                    <td className="fs-6 fw-bold text-center cell-pad truck-width">
                                                                        <span>#{interchange.truckNumber}<br></br></span>
                                                                        <span>{interchange.truckLicense}</span>
                                                                    </td>
                                                                </>
                                                            )
                                                            : (
                                                                <td className="fs-6 fw-bold text-center cell-pad">
                                                                    <span>{interchange.shippingCompany}</span>
                                                                    <br />
                                                                    <span>#{interchange.truckNumber}</span>
                                                                    <br />
                                                                    <span>{interchange.truckLicense}</span>
                                                                </td>
                                                            )
                                                        }

                                                        {interchange.status === 0 ?
                                                            (
                                                                interchange.bookingPreassignedItems?.length > 0 ?
                                                                    (
                                                                        <td className="fs-7 text-center cell-pad booking-width">
                                                                            <div style={{ background: 'red' }} className="rounded p-2 inline-block">
                                                                                <span className="font-bold text-white">
                                                                                    Booking P/A {interchange.bookingNumber}
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                    ) :
                                                                    (
                                                                        <td className="fs-7 text-center cell-pad booking-width">
                                                                            <span>Booking</span>
                                                                            <br />
                                                                            <span>{interchange.bookingNumber}</span>
                                                                        </td>
                                                                    )
                                                            )
                                                            : (
                                                                <td className="fs-7 text-center cell-pad booking-width">
                                                                    <span>BK# {interchange.bookingNumber}</span>
                                                                    <br />
                                                                    <span>{interchange.bookingComments}</span>
                                                                </td>
                                                            )
                                                        }

                                                        {interchange.status === 0 ?
                                                            (
                                                                interchange.bookingPreassignedItems?.length > 0 ?
                                                                    (
                                                                        <td className="fs-7 text-center cell-pad booking-width">
                                                                            <span style={{ color: 'red', fontWeight: 'bold' }}>State</span>
                                                                            <br />
                                                                            <span style={{ color: 'red', fontWeight: 'bold' }}>{interchange.bookEquipCondition?.toUpperCase()}</span>
                                                                        </td>
                                                                    ) :
                                                                    (
                                                                        <></>
                                                                    )
                                                            )
                                                            : (
                                                                <></>
                                                            )
                                                        }


                                                        {interchange.status === 1 ? <td className="cell-pad">
                                                            <Button type="primary" className="rounded" style={{ paddingLeft: '0.4em', paddingRight: '0.4em' }} onClick={i => handleApprove(interchange.interchangeNumber.value, interchange.equipmentPhoto, interchange.signature)}>Approve</Button>
                                                            <Button type="primary" className="rounded" style={{ marginLeft: '0.4em' }} onClick={i => handleCancel(interchange.interchangeNumber.value)}>Cancel</Button>
                                                        </td>
                                                            : <td></td>}
                                                    </tr>
                                                ))}
                                            </tbody>
                                            <tfoot className="bg-secondary">
                                                <tr className="border border-5 border-secondary">
                                                    <td className="fs-6 fw-bold cell-pad" colSpan="3">
                                                        <div className="row">
                                                            <Form.Label className="col-sm-3">Terminal</Form.Label>
                                                            <div className="col-sm-9">
                                                                <Form.Select value={filterState.terminal} inline="true" className="fs-7 terminal-select" size="sm" onChange={i => handleFilters(i, "terminal")}>
                                                                    <option value="ALL">All Terminals</option>
                                                                    {state.terminals.map((terminal) => (<option value={terminal}>{terminal}</option>))}
                                                                </Form.Select>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td colSpan="4"></td>
                                                    <td colSpan="4">
                                                        <div className="text-end" onChange={i => handleFilters(i, "equipment")}>
                                                            <Form.Check name="equipment" value="CONTAINERS" type="radio" inline="true" label="Containers / Reefers" className="fs-7 fw-bold" />
                                                            <Form.Check name="equipment" value="GENSETS" type="radio" inline="true" label="Gensets" className="fs-7 fw-bold" />
                                                            <Form.Check name="equipment" value="BOTH" type="radio" inline="true" label="Both" className="fs-7 fw-bold" defaultChecked={filterState.equipment === "BOTH"} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>

                                </div>
                            }
                            {
                                state.loading == true &&
                                <div className="border border-5 border-secondary  overflow" style={{ borderColor: 'gray', padding: '0px', width: '100%', textAlign: 'center', fontSize: '32px' }}>
                                    <i className="fa fa-spinner fa-spin" style={{ paddingTop: '10px' }}></i>
                                </div>

                            }

                        </div>
                    </div>

                </div>


            </>
        );
    };

    const handleClear = () => {
        if (sigRef.clear) {
            sigRef.clear();
            setModalState2({ ...modalState2, signature: null, isValid: false });
        }
    }

    const handleApprove = (interchangeNumber) => {
        var equip = state.filteredInterchanges.filter(x => x.interchangeNumber.value === interchangeNumber);
        if (equip && equip != null && equip.length > 0) {
            var stepZeroVisibleOnOpen = (equip[0].bookingComments && equip[0].bookingComments != null && equip[0].bookingComments.trim() !== "") || equip[0].loaded === true;
            var stepOneVisibleOnOpen = !stepZeroVisibleOnOpen;

            let photos = modalState2.photos;
            photos[0].photo = equip[0].photo1;
            photos[1].photo = equip[0].photo2;
            photos[2].photo = equip[0].photo3;
            if (equip[0].attached === "T") {
                photos[3].photo = equip[0].photo4;
            }
            if (equip[0].bookHumidity) {
                photos[4].photo = equip[0].photo5;
            }
            if (equip[0].ventSet?.set === "OPEN") {
                photos[5].photo = equip[0].photo6;
            }

            var isValid = equip[0].photo1 != null && equip[0].photo1 !== "" &&
                equip[0].photo2 != null && equip[0].photo2 !== "" &&
                equip[0].photo3 != null && equip[0].photo3 !== "" &&
                (equip[0].attached !== "T" || (equip[0].photo4 != null && equip[0].photo4 !== "")) &&
                (!equip[0].bookHumidity || (equip[0].photo5 != null && equip[0].photo5 !== "")) &&
                (equip[0].ventSet?.set !== "OPEN" || (equip[0].photo6 != null && equip[0].photo6 !== ""));

            setTakePhoto1(!(equip[0].photo1 != null && equip[0].photo1 !== ""));
            setTakePhoto2(!(equip[0].photo2 != null && equip[0].photo2 !== ""));
            setTakePhoto3(!(equip[0].photo3 != null && equip[0].photo3 !== ""));
            if (equip[0].attached === "T") {
                setTakePhoto4(!(equip[0].photo4 != null && equip[0].photo4 !== ""));
            }
            if (equip[0].bookHumidity) {
                setTakePhoto5(!(equip[0].photo5 != null && equip[0].photo5 !== ""));
            }
            if (equip[0].ventSet?.set === "OPEN") {
                setTakePhoto6(!(equip[0].photo6 != null && equip[0].photo6 !== ""));
            }


            setModalState2({
                ...modalState2, visible: true, visibleStep0: stepZeroVisibleOnOpen, visibleStep1: stepOneVisibleOnOpen, equip: equip, interchangeNumber: interchangeNumber, equipmentPhoto: equip[0].equipmentPhoto, signature: equip[0].signature, isValid: isValid
            });
        }
    };

    const handleNextStep = () => {
        if (modalState2.visibleStep0 === true)
            handleNextStep0();
        else if (modalState2.visibleStep1 === true)
            handleNextStep1();
        else if (modalState2.visibleStep2 === true)
            handleNextStep2();
    }

    const updatePhotos = (intNum) => {
        var equip = state.filteredInterchanges.filter(e => e.interchangeNumber.value == intNum);
        if (equip && equip != null && equip.length > 0) {
            equip[0].photo1 = modalState2.photos[0].photo;
            equip[0].libelle1 = modalState2.photos[0].text;
            equip[0].photo2 = modalState2.photos[1].photo;
            equip[0].libelle2 = modalState2.photos[1].text;
            equip[0].photo3 = modalState2.photos[2].photo;
            equip[0].libelle3 = modalState2.photos[2].text;
            if (equip[0].attached === "T") {
                equip[0].photo4 = modalState2.photos[3].photo;
                equip[0].libelle4 = modalState2.photos[3].text;
            }
            if (equip[0].bookHumidity) {
                equip[0].photo5 = modalState2.photos[4].photo;
                equip[0].libelle5 = modalState2.photos[4].text;
            }
            if (equip[0].ventSet?.set === "OPEN") {
                equip[0].photo6 = modalState2.photos[5].photo;
                equip[0].libelle6 = modalState2.photos[5].text;
            }
        }

        var equipInterChange = state.interchanges.filter(e => e.interchangeNumber.value == intNum);
        if (equipInterChange && equipInterChange != null && equipInterChange.length > 0) {
            equipInterChange[0].photo1 = modalState2.photos[0].photo;
            equipInterChange[0].libelle1 = modalState2.photos[0].text;
            equipInterChange[0].photo2 = modalState2.photos[1].photo;
            equipInterChange[0].libelle2 = modalState2.photos[1].text;
            equipInterChange[0].photo3 = modalState2.photos[2].photo;
            equipInterChange[0].libelle3 = modalState2.photos[2].text;
            if (equip[0].attached === "T") {
                equipInterChange[0].photo4 = modalState2.photos[3].photo;
                equipInterChange[0].libelle4 = modalState2.photos[3].text;
            }
            if (equip[0].bookHumidity) {
                equipInterChange[0].photo5 = modalState2.photos[4].photo;
                equipInterChange[0].libelle5 = modalState2.photos[4].text;
            }
            if (equip[0].ventSet?.set === "OPEN") {
                equipInterChange[0].photo6 = modalState2.photos[5].photo;
                equipInterChange[0].libelle6 = modalState2.photos[5].text;
            }
        }
    }

    const updateSignature = (intNum) => {
        var equip = state.filteredInterchanges.filter(e => e.interchangeNumber.value === intNum);
        if (equip && equip != null && equip.length > 0)
            equip[0].signature = modalState2.signature;

        var equipInterChange = state.interchanges.filter(e => e.interchangeNumber.value === intNum);
        if (equipInterChange && equipInterChange != null && equipInterChange.length > 0)
            equipInterChange[0].signature = modalState2.signature;

    }

    const updateEquipPhoto = (intNum, photo) => {
        var equip = state.filteredInterchanges.filter(e => e.interchangeNumber.value == intNum);
        if (equip && equip != null && equip.length > 0)
            equip[0].equipmentPhoto = photo;

        var equipInterChange = state.interchanges.filter(e => e.interchangeNumber.value == intNum);
        if (equipInterChange && equipInterChange != null && equipInterChange.length > 0)
            equipInterChange[0].equipmentPhoto = photo;
    }
    const updateEquipSignature = (intNum, signature) => {
        var equip = state.filteredInterchanges.filter(e => e.interchangeNumber.value == intNum);
        if (equip && equip != null && equip.length > 0)
            equip[0].signature = signature;

        var equipInterChange = state.interchanges.filter(e => e.interchangeNumber.value == intNum);
        if (equipInterChange && equipInterChange != null && equipInterChange.length > 0)
            equipInterChange[0].signature = signature;

    }

    const Approve = () => {
        setModalState2({ ...modalState2, isValid: false });
        Services.updateInterchange(modalState2.interchangeNumber, { Status: iOutStatus.Completed }, (response) => {
            if (response.status === 200) {
                setRefresh(Date.now());
                setModalState2(modalInitialState2);
            }
        }, () => {
            setModalState2(modalInitialState2);
        });
        setModalState(modalInitialState);
    }

    const handleCancelModal2 = () => {
        setModalState2(modalInitialState2);
    }

    const handleCancel = (interchangeNumber) => {
        if (window.confirm('Are you sure?')) {
            Services.updateInterchange(interchangeNumber, { Cancel: true }, (response) => {
                if (response.status === 200) {
                    setRefresh(Date.now());

                }
            });
            setModalState(modalInitialState);
        }
    };

    const updateFilterState = (filter, value) => {
        if (!filter && !value) {
            setFilterState({
                ...filterState,
                lastUpdatedPropName: ""
            });
        } else {
            setFilterState({
                ...filterState,
                [filter]: value,
                lastUpdatedPropName: filter
            });
        }
    };

    const handleFilters = (e, filter) => {
        updateFilterState(filter, e.target.value);
    };

    const handleKeyDown = (e, funcToExec) => {
        if (e.key === 'Enter' || e.key === 'Tab') {
            funcToExec();
        }
    };

    const handleFindEquipment = () => {
        if (findEquipment) {
            Services.getEquipment(findEquipment, modalState.ioutNumber, (response) => {
                setModalState({
                    ...modalState,
                    equipment: response.data.equipment,
                    errorEquipment: response.data.errorMessage,
                    extraInfo: response.data.extraInfo,
                    isValid: response.data.isValid,
                    hasWarning: response.data.hasWarning,
                    attachGenset: response.data.attachGenset,
                    genset: null,
                    errorGenset: null
                });
            });
        }
        else {
            setModalState({
                ...modalState,
                equipment: null,
                errorEquipment: "Equipment code cannot be blank! Please try again.",
                isValid: false,
                extraInfo: null,
                genset: null,
                errorGenset: null,
                attachGenset: false
            });
        }
    };

    const handleFindGenset = (callBack) => {
        if (findGenset)
            Services.isGensetAttachable(findGenset, modalState.ioutNumber, modalState.bookingNumber, (response) => {
                setModalState({
                    ...modalState,
                    genset: response.data.equipment,
                    errorGenset: response.data.errorMessage,
                    isValid: response.data.isValid && modalState.isValid
                }, callBack);
            });
        else {
            setModalState({
                ...modalState,
                genset: null,
                errorGenset: null,
                isValid: modalState.isValid && !modalState.attachGenset
            }, callBack);
        }
    };

    const handleCancelModal = () => {
        setFindEquipment("");
        setFindGenset("");
        setModalState(modalInitialState);
    };

    const handleOnCreateModal = () => {

        if (findGenset && !modalState.genset) {
            handleFindGenset(internalHandleOnCreateModal);
        } else {
            internalHandleOnCreateModal();
        }
    };

    const internalHandleOnCreateModal = () => {
        Services.updateInterchange(modalState.ioutNumber, { EquipmentNumber: modalState.equipment.containerNumber, AttachedGensetNumber: modalState.genset?.containerNumber, Comment: modalState.bookingComments, Status: iOutStatus.WaitingApproval }, (response) => {

            if (response.status === 200) {
                setRefresh(Date.now());
            }
        });

        setModalState(modalInitialState);
        setFindEquipment('');
        setFindGenset('');
    };

    const [pendingInterchange, setPendingInterchange] = useState(null);
    useEffect(() => {
        if (pendingInterchange) {
            const interchange = state.filteredInterchanges.find(i => i.interchangeNumber.value === pendingInterchange);
            if (interchange?.bookingPreassignedItems?.length > 0) {
                setPreAssignModal({
                    visible: true,
                    bookingPreassignedItems: interchange.bookingPreassignedItems
                });
            } else {
                setModalState(prev => ({
                    ...prev,
                    visible: true
                }));
            }
            setPendingInterchange(null);
        }
    }, [modalState]);
    const handleLineClick = (ioutNumber, status, bookingComments, bookingNumber, attached, loaded, bookHumidity, ventSet, fromLocation, toLocation) => {
        if (status === iOutStatus.WaitingApproval) return;

        setModalState({
            ...modalState,
            loaded,
            bookingComments,
            bookingNumber,
            ioutNumber,
            fromLocation,
            toLocation,
            attached,
            ventInfo: ventSet,
            bookHumidity
        });
        setPendingInterchange(ioutNumber);
    };

    //const handleLineClick = (ioutNumber, status, bookingComments, bookingNumber, attached, loaded, bookHumidity, ventSet, fromLocation, toLocation) => {
    //    if (status === iOutStatus.WaitingApproval)
    //        return;

    //    const interchange = state.filteredInterchanges.find(i => i.interchangeNumber.value === ioutNumber);

    //    if (interchange?.bookingPreassignedItems?.length > 0) {
    //        setPreAssignModal({
    //            visible: true,
    //            bookingPreassignedItems: interchange.bookingPreassignedItems
    //        });
    //    } else {
    //        setModalState({
    //            ...modalState,
    //            visible: true,
    //            loaded: loaded,
    //            bookingComments: bookingComments,
    //            bookingNumber: bookingNumber,
    //            ioutNumber,
    //            fromLocation,
    //            toLocation,
    //            attached: attached,
    //            ventInfo: ventSet,
    //            bookHumidity: bookHumidity
    //        });
    //    }
    //};

    var getPng = (idImg) => {
        var img = document.getElementById(idImg)
        var canvas = document.createElement('canvas');
        var context = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;
        context.drawImage(img, 0, 0);
        return canvas.toDataURL('image/png');
    };

    const onCapture = (imageData) => {
        var p = modalState2.photos[0];
        if (p)
            p.photo = imageData.webP

        var isValid = checkValdityPhotos();
        setModalState2({ ...modalState2, isValid: isValid, equipmentPhoto: imageData.webP, imgFile: imageData.file });
        setTakePhoto1(false);
    };

    const onCapture2 = (imageData) => {
        var p = modalState2.photos[1];
        if (p)
            p.photo = imageData.webP

        var isValid = checkValdityPhotos();
        setModalState2({ ...modalState2, isValid: isValid, equipmentPhoto: imageData.webP, imgFile: imageData.file });
        setTakePhoto2(false);
    };

    const onCapture3 = (imageData) => {
        var p = modalState2.photos[2];
        if (p)
            p.photo = imageData.webP

        var isValid = checkValdityPhotos();
        setModalState2({ ...modalState2, isValid: isValid, equipmentPhoto: imageData.webP, imgFile: imageData.file });
        setTakePhoto3(false);
    };

    const onCapture4 = (imageData) => {
        var p = modalState2.photos[3];
        if (p)
            p.photo = imageData.webP

        var isValid = checkValdityPhotos();
        setModalState2({ ...modalState2, isValid: isValid, equipmentPhoto: imageData.webP, imgFile: imageData.file });
        setTakePhoto4(false);
    };

    const onCapture5 = (imageData) => {
        var p = modalState2.photos[4];
        if (p)
            p.photo = imageData.webP

        var isValid = checkValdityPhotos();
        setModalState2({ ...modalState2, isValid: isValid, equipmentPhoto: imageData.webP, imgFile: imageData.file });
        setTakePhoto5(false);
    };

    const onCapture6 = (imageData) => {
        var p = modalState2.photos[5];
        if (p)
            p.photo = imageData.webP

        var isValid = checkValdityPhotos();
        setModalState2({ ...modalState2, isValid: isValid, equipmentPhoto: imageData.webP, imgFile: imageData.file });
        setTakePhoto6(false);
    };

    const checkValdityPhotos = () => {
        for (var i = 0; i < 3; i++) {
            if (modalState2.photos[i].photo == null || modalState2.photos[i].photo === "")
                return false;
        }

        if (modalState2.equip[0].attached === "T" && (modalState2.photos[3].photo == null || modalState2.photos[3].photo === "")) {
            return false;
        }

        if (modalState2.equip[0].bookHumidity && (modalState2.photos[4].photo == null || modalState2.photos[4].photo === "")) {
            return false;
        }

        if (modalState2.equip[0].ventSet?.set === "OPEN" && (modalState2.photos[5].photo == null || modalState2.photos[5].photo === "")) {
            return false;
        }

        return true;
    }

    const handleNextStep0 = () => {
        var isValid = checkValdityPhotos();
        setModalState2({ ...modalState2, isValid: isValid, visibleStep0: false, visibleStep1: true, visibleStep2: false });
    }

    const handleNextStep1 = () => {

        setModalState2({ ...modalState2, isValid: false });
        Services.updateInterchangePhoto(modalState2.interchangeNumber, {
            photo1: getPng('imgPhoto'),
            photo2: getPng('imgPhoto2'),
            photo3: getPng('imgPhoto3'),
            photo4: modalState2.equip && modalState2.equip[0].attached === "T" ? getPng('imgPhoto4') : null,
            photo5: modalState2.equip && modalState2.equip[0].bookHumidity ? getPng('imgPhoto5') : null,
            photo6: modalState2.equip && modalState2.equip[0].ventSet?.set === "OPEN" ? getPng('imgPhoto6') : null,
            textPhoto1: modalState2.photos[0].text,
            textPhoto2: modalState2.photos[1].text,
            textPhoto3: modalState2.photos[2].text,
            textPhoto4: modalState2.equip && modalState2.equip[0].attached === "T" ? modalState2.photos[3].text : null,
            textPhoto5: modalState2.equip && modalState2.equip[0].bookHumidity ? modalState2.photos[4].text : null,
            textPhoto6: modalState2.equip && modalState2.equip[0].ventSet?.set === "OPEN" ? modalState2.photos[5].text : null,
            signature: modalState2.signature
        }, (response) => {

            if (response.status === 200) {
                updatePhotos(modalState2.interchangeNumber);
                setModalState2({
                    ...modalState2, isValid: modalState2.signature && modalState2.signature != null, visibleStep1: false, visibleStep2: true
                });

            }
        }, () => {
            setModalState2({
                ...modalState2, isValid: true, visibleStep1: true, visibleStep2: false
            });

        });
    }

    const handleNextStep2 = () => {

        setModalState2({ ...modalState2, isValid: false });
        Services.updateInterchangePhoto(modalState2.interchangeNumber, {
            photo1: "",
            photo2: "",
            photo3: "",
            photo4: "",
            photo5: "",
            photo6: "",
            textPhoto1: "",
            textPhoto2: "",
            textPhoto3: "",
            textPhoto4: "",
            textPhoto5: "",
            textPhoto6: "",
            signature: modalState2.signature,
            isSignature: true
        }, (response) => {

            if (response.status === 200) {
                updateSignature(modalState2.interchangeNumber);
                Approve();
            }
        }, () => {
            setModalState2({
                ...modalState2, isValid: true, visibleStep1: false, visibleStep2: true
            });

        });
    }


    const handleBack = () => {
        setModalState2({ ...modalState2, isValid: true, visibleStep1: true, visibleStep2: false });

    }

    // Use useCallback to avoid unexpected behaviour while rerendering
    const onError = useCallback((error) => { console.log(error) }, []);

    // Use useMemo to avoid unexpected behaviour while rerendering
    const config = useMemo(() => ({
        video: !isMobile ? true : {
            facingMode: {
                exact: "environment"
            }
        }
    }), []);

    const render = () => {
        let contents = renderActiveInterchangeTable();
        return <div>{contents}</div>;
    };

    const filterData = (item, filter) => {
        if (item.bookingNumber && item.bookingNumber.toUpperCase().includes(filter.toUpperCase()))
            return true;
        if (item.client && item.client.toUpperCase().includes(filter.toUpperCase()))
            return true;
        if (item.color && item.color.toUpperCase().includes(filter.toUpperCase()))
            return true;
        if (item.comment && item.comment.value && item.comment.value.toUpperCase().includes(filter.toUpperCase()))
            return true;
        if (item.equipment && item.equipment.value && item.equipment.value.toUpperCase().includes(filter.toUpperCase()))
            return true;
        if (item.interchangeNumber && item.interchangeNumber.value && item.interchangeNumber.value.toUpperCase().includes(filter.toUpperCase()))
            return true;
        if (item.logDateTime && item.logDateTime.toString().toUpperCase().includes(filter.toUpperCase()))
            return true;
        if (item.logDateTime && moment(item.logDateTime).format("YYYY-MM-DD HH:mm").toUpperCase().includes(filter.toUpperCase()))
            return true;
        if (item.repo && item.repo.value && item.repo.value.toUpperCase().includes(filter.toUpperCase()))
            return true;
        if (item.shippingCompany && item.shippingCompany.toUpperCase().includes(filter.toUpperCase()))
            return true;
        if (item.status && item.status.toString().toUpperCase().includes(filter.toUpperCase()))
            return true;
        if (item.temperature && item.temperature.value && item.temperature.value.toString().toUpperCase().includes(filter.toUpperCase()))
            return true;
        if (item.terminal && item.terminal.toUpperCase().includes(filter.toUpperCase()))
            return true;
        if (item.truckLicense && item.truckLicense.toUpperCase().includes(filter.toUpperCase()))
            return true;
        if (item.truckNumber && item.truckNumber.toUpperCase().includes(filter.toUpperCase()))
            return true;
        if (item.ventSet && item.ventSet.value && item.ventSet.value.toString().toUpperCase().includes(filter.toUpperCase()))
            return true;

        return false;
    };

    async function populateActiveInterchangeData() {
        Services.getActiveInterchange((response) => {
            setState({
                interchanges: response.data.interchange,
                filteredInterchanges: response.data.interchange.filter(i => i.status === parseInt(filterState.status)),
                terminals: response.data.terminals,
                currentUserTerminal: response.data.currentUserTerminal,
                loading: false,
            });
        });
    }

    useEffect(() => {
        if (state.interchanges.length > 0) {
            if (firstLoad) {
                setFirstLoad(false);
                if (state.currentUserTerminal !== null && state.currentUserTerminal !== "") {
                    updateFilterState("terminal", state.currentUserTerminal);
                }
            } else {
                updateFilterState();
            }
        }
    }, [state.interchanges]);

    useEffect(() => {
        if (filterState.lastUpdatedPropName === "status") {
            populateActiveInterchangeData();
        } else {
            let filtered = state.interchanges.filter((i) => filterData(i, filterState.search));
            filtered = filtered.filter(i => i.status === parseInt(filterState.status));
            if (filterState.terminal !== "ALL")
                filtered = filtered.filter(i => i.terminal === filterState.terminal);
            if (filterState.equipment !== "BOTH") {
                if (filterState.equipment === "GENSETS")
                    filtered = filtered.filter(i => i.equipment.value.indexOf("GENSET") > -1);
                else
                    filtered = filtered.filter(i => i.equipment.value.indexOf("GENSET") == -1);
            }
            if (state.interchanges[0]) {
                setState({
                    ...state,
                    filteredInterchanges: filtered,
                });
            }
        }
    }, [filterState]);

    useEffect(() => {
        populateActiveInterchangeData();
    }, [refresh]);

    useEffect(() => {
        if (modalState2 && modalState2.signature && modalState2.signature != null && modalState2.visibleStep2 == true) {
            sigRef
                .fromDataURL(modalState2.signature);
        }
    }, [modalState2.visibleStep2]);


    return render();
};
