import React, { useState, useCallback, useMemo, useEffect } from "react";
import { isMobile } from 'react-device-detect';
import ImageCapture from 'react-image-data-capture';
import { Form } from "react-bootstrap";
import Services from "../services/services";
import { Modal, Button, Row, Col, Table, Select, Input } from 'antd';
import 'antd/dist/antd.css';
import useCollapse from 'react-collapsed';
import 'font-awesome/css/font-awesome.min.css';
import { IdleTimerProvider } from 'react-idle-timer';
import { DeleteOutlined, CameraOutlined, ReloadOutlined, CloseOutlined, LeftOutlined, DownloadOutlined, SaveOutlined, UploadOutlined } from '@ant-design/icons';
//import { SearchOutlined, FolderOutlined } from '@ant-design/icons';
//import { SearchOutlined, FolderOutlined, CameraOutlined, ReloadOutlined, SaveOutlined, UploadOutlined, CloseOutlined } from '@ant-design/icons';
import { useLocation, useHistory, Link, useParams } from 'react-router-dom';
import _db from "../services/data-service";
import moment from 'moment';

import "./Lift.css";

const { Option } = Select;
const { TextArea } = Input;

const initialQueryResult = {
    cntr_num: "",
    validCheckDigit: false,
    size: "",
    type: "",
    height: "",
    line: "",
    rs_ta_terminal: "",
    location: "",
    year: "",
    status: "",
    bdjob: "",
    bdStatus: "",
    damJob: "",
    damStatus: "",
    bdComment: "",
    maintComment: "",
    lastPtiComment: "",
    lastPtiDate: "",
    lastPtiType: "",
    ptiType: "",
    isoCode: "",
    onHold: "",
    commentOH: "",
    outOfService: "",
    bodyDamage: "",
    color: "",
    isOut: false,
    daysIn: 0,
    inMaint: false,
    inFleet: false,
    equipNotes: "",
    eqPhotoModel: {}
}

const modalInitialState = {
    visible: false,
    takingPhotoOrPreview: true,
    isValid: false,
    photos: [],
    videos: [],
    photoType: "",
    currentEquipNum: null,
    endedWithSuccessfulSave: false
}

const initialState = {
    terminals: [],
    eqPhotos: [],
    filteredEqPhotos: [],
    currentUserTerminal: null,
    loading: true,
};

const filterInitialState = {
    terminal: "ALL",
    search: "",
}

const viewModalInitialState = {
    visible: false,
    photo: "",
    video: "",
    fileName: "",
    isVideo: false
}

const PtiPhoto = () => {
    const location = useLocation();
    const history = useHistory()

    let oRecord = { ...location.state?.record } //Original record...
    let oItems = { ...location.state?.items } //Original...
    const { equipNum: container, birthDate, lifeNum, numPhases: phaseNum, line, sTerminal, sLocation, location: _location } = oRecord;

    //console.log("Original R:", oRecord)

    const [state, setState] = useState(initialState);
    const [filterState, setFilterState] = useState(filterInitialState);
    const [viewModalState, setViewModalState] = useState(viewModalInitialState);
    const [firstLoad, setFirstLoad] = useState(true);

    const { equip } = useParams();
    const [loadedEquip, setLoadedEquip] = useState(equip?.toUpperCase());

    const [isExpanded, setExpanded] = useState(true)
    const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded })


    const [qres, setQres] = useState(initialQueryResult);
    //const [hasLocation, setHasLocation] = useState(false);
    const [loading, setLoading] = useState(false);
    const [sizeTypeHeight, setSizeTypeHeight] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [modalState, setModalState] = useState(modalInitialState);
    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [showModalFailure, setShowModalFailure] = useState(false);
    const [modalWait, setModalWait] = useState(false);

    //const onIdleTimerProviderPrompt = () => {
    //}

    //const onIdleTimerProviderActive = (event) => {
    //}

    //const onIdleTimerProviderAction = (event) => {
    //}

    //const onIdleTimerProviderIdle = () => {
    //    handleReload()
    //}

    const handleViewModalExit = () => {
        setViewModalState(viewModalInitialState);
    }

    const getFileName = (path) => {
        return path.split('/').pop();
    };

    const downloadMedia = (url, fileName) => {
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName.replace(/\.jpg$/i, '.jpeg');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleViewModalDownload = () => {
        const url = viewModalState.isVideo ? viewModalState.video : viewModalState.photo;
        downloadMedia(url, viewModalState.fileName);
    };

    //const handleReload = () => {
    //    var newUrl = loadedEquip ? `/EquipmentPhoto/${(loadedEquip ?? "").toUpperCase()}` : "/EquipmentPhoto";
    //    window.location.href = newUrl;
    //};

    function backToPti() {
        history.push({
            pathname: "/pti/pti", //search: "?id=5",hash: "#react",
            state: {
                record: { ...oRecord },
                items: { ...oItems }
            }
        })
    }

    const onError = useCallback((error) => { console.log(error) }, []);

    const validateBeforeSave = () => {
        var message = "";

        if (!qres.inMaint && !qres.inFleet) {
            var line = qres?.eqPhotoModel?.lineCode?.trim();
            var iso = qres?.eqPhotoModel?.isoCode?.trim();

            if (!line)
                message += "You must choose a line.\n";
            if (!iso)
                message += "You must choose an ISO code.\n";
        }

        if (!qres.inMaint || qres.isOut) {
            var site = qres?.eqPhotoModel?.offsiteLocation?.trim();
            var offsiteChecked = qres?.eqPhotoModel?.offsite;

            if (!site)
                message += "You must specify the offsite location.\n";
            if (!offsiteChecked)
                message += "You must check the offsite box.\n";
        }

        if (message !== "") {
            window.alert(message);
            return false;
        }

        return true;
    }

    const handleSave = () => {

        if (!validateBeforeSave()) {
            return;
        }

        if (!window.confirm('Are you sure?')) {
            return;
        }

        Services.saveEquipmentPhotoInfo(qres.cntr_num, {
            id: qres.eqPhotoModel.id,
            line: qres.eqPhotoModel.lineCode,
            site: qres.eqPhotoModel.offsiteLocation,
            terminal: qres.eqPhotoModel.terminal,
            location: qres.eqPhotoModel.location,
            isoCode: qres.eqPhotoModel.isoCode,
            offsite: qres.eqPhotoModel.offsite,
            offsiteComment: qres.eqPhotoModel.offsiteComment,
            confirmed: qres.eqPhotoModel.confirmed
        }, (response) => {
            if (response && response != null && response.data) {
                window.alert("Information saved successfully.")
            }
            else {
                window.alert("Error in saving information...!")
            }
        });
    }

    const handleModalSave = () => {
        setModalWait(true);
        //if (!validateBeforeSave()) {
        //    setModalWait(false);
        //    return;
        //}

        const formData = new FormData();
        modalState.photos.forEach((photo, index) => {
            formData.append(`photos`, photo.split(',')[1]);
        });
        modalState.videos.forEach((video, index) => {
            formData.append(`videos`, video.split(',')[1]);
        });

        //const { equipNum: container, birthDate, lifeNum, numPhases: phaseNum, line, sTerminal, sLocation } = oRecord;

        formData.append('line', line ?? "");
        //formData.append('site', qres?.eqPhotoModel?.offsiteLocation ?? "");
        formData.append('terminal', sTerminal ?? "");
        formData.append('location', _location ?? "");
        //formData.append('isoCode', qres?.eqPhotoModel?.isoCode ?? "");
        formData.append('lifeNum', lifeNum ?? "");
        formData.append('phaseNum', phaseNum ?? "");

        //formData.append('line', qres?.eqPhotoModel?.lineCode ?? "");
        //formData.append('site', qres?.eqPhotoModel?.offsiteLocation ?? "");
        //formData.append('terminal', qres?.eqPhotoModel?.terminal ?? "");
        //formData.append('location', qres?.eqPhotoModel?.location ?? "");
        //formData.append('isoCode', qres?.eqPhotoModel?.isoCode ?? "");
        //formData.append('offsite', qres?.eqPhotoModel?.offsite ?? false);
        //formData.append('offsiteComment', qres?.eqPhotoModel?.offsiteComment ?? "");
        //formData.append('confirmed', qres?.eqPhotoModel?.confirmed ?? false);

        _db.savePtiMedia(modalState.currentEquipNum, formData, (response) => {
            if (response && response != null && response.data) {
                setModalWait(false);
                setShowModalSuccess(true);
                setModalState({ ...modalState, endedWithSuccessfulSave: true })
                setTimeout(() => {
                    setShowModalSuccess(false);
                }, 5000);
                populateEqPhotoData()
            }
            else {
                setModalWait(false);
                setShowModalFailure(true);
                setTimeout(() => {
                    setShowModalFailure(false);
                }, 5000);
            }
        });
    };

    const handleModalExit = () => {
        var currentEquip = modalState.currentEquipNum
        var endedWithSuccessfulSave = modalState.endedWithSuccessfulSave
        if (currentEquip && endedWithSuccessfulSave) {
            handleSearch(currentEquip);
        }
        setModalState(modalInitialState);
        //
        //setExpanded((prevExpanded) => !prevExpanded)
        setExpanded(true)
    }

    const handleMediaCapture = () => {
        setModalState({ ...modalState, visible: true, currentEquipNum: container.trim() });
        //setModalState({ ...modalState, visible: true, currentEquipNum: qres.cntr_num });
    }

    const handleReset = () => {
        setModalState({ ...modalState, isValid: false, photos: [], videos: [], takingPhotoOrPreview: true });
    }

    const handleFileUpload = (event) => {
        var files = event.target.files;
        if (files) {
            var promises = [];
            for (var index = 0; index < files.length; index++) {
                promises.push(toBase64(files[index]));
            }
            Promise.all(promises).then(results => {
                var base64Files = [];
                var base64Videos = [];
                results.forEach(data => {
                    if (data && data !== null && data !== "") {
                        if (data.startsWith('data:image')) {
                            base64Files.push(data);
                        } else if (data.startsWith('data:video') || data.startsWith('data:application')) {
                            base64Videos.push(data);
                        }
                    }
                });
                setModalState({
                    ...modalState,
                    isValid: base64Files.length > 0 || base64Videos.length > 0,
                    photos: base64Files,
                    videos: base64Videos,
                    takingPhotoOrPreview: false
                });
            }).catch(error => {
                console.error("Error converting files to base64:", error);
            });
        }
    };

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

    const base64ToBlobUrl = (base64) => {
        const mimeType = base64.split(',')[0].match(/:(.*?);/)[1];

        const byteCharacters = atob(base64.split(',')[1]);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        const blob = new Blob([byteArray], { type: mimeType });
        return URL.createObjectURL(blob);
    }

    const onCapture = (imageData) => {
        setModalState({
            ...modalState, isValid: imageData && imageData.webP && imageData.webP !== null && imageData.webP !== ""
            , photos: [imageData.webP], videos: [], takingPhotoOrPreview: false
        });
    };

    const config = useMemo(() => ({
        video: !isMobile ? true : {
            facingMode: {
                exact: "environment"
            }
        }
    }), []);

    const getBorderStyle = (condition) => {
        if (condition) {
            return { borderColor: 'lightgreen', borderStyle: 'double' };
        } else {
            return { borderColor: null, borderStyle: null };
        }
    };

    const getStatusColor = (status) => {
        var color = "Grey";

        if (!status)
            return color;

        if (status.includes("AVAILABLE") || status.includes("LEASE IN"))
            color = "Green";
        else if (status.includes("TO BE P"))
            color = "Blue";
        else if (status.includes("END OF CYCLE") || status.includes("LEASE OUT") || status.includes("OUT ON LEASE"))
            color = "YellowGreen";
        else if (status.includes("OUT OF DEPOT"))
            color = "Violet";
        else if (status.includes("MECH. DAMAGE") || status.includes("MECH/DAMAGED"))
            color = "Red";
        else if (status.includes("BODY DAMAGE"))
            color = "Magenta";

        return color;
    }

    const handleSearch = (equipNumber) => {
        if (equip && equip !== equipNumber) {
            history.push(`/PtiPhoto/${equipNumber}`);
        } else {
            setLoading(true);
            Services.getEquipmentQuery(equipNumber, (response) => {
                setLoading(false);

                if (response && response != null && response.data) {
                    setQres(response.data);
                    var message = "";
                    if (!response.data.validCheckDigit) {
                        message += "Warning...! Invalid check digit.\n\n";
                    }

                    //if (response.data.cntr_num?.trim() && (!response.data.inMaint || response.data.isOut) && (!response.data.eqPhotoModel || response.data.eqPhotoModel.id <= 0)) {
                    //    message += "Attention: This equipment is OFF-SITE and lacks photos. Please add at least one photo to save this record. ISO code, line, and site are required.";
                    //}

                    if (message) {
                        window.alert(message);
                    }

                    setSizeTypeHeight((response.data.size?.trim() ? response.data.size?.trim() : "") + (response.data.type?.trim() ? response.data.type?.trim() : "") + (response.data.height?.trim() ? response.data.height?.trim() : ""));
                }
                else {
                    setQres(initialQueryResult);
                    setSizeTypeHeight("");
                }
            });
        }
    };


    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            responsive: ['lg'],
            render: (text) => <span className="fs-6 fw-bold">{text}</span>
        },
        {
            title: 'Picture',
            dataIndex: 'photo',
            key: 'photo',
            align: 'center',
            render: (text, record) => (
                <div>
                    {record.photo && (
                        <img
                            src={`data:image/png;base64,${record.photo}`}
                            alt="captured-img"
                            style={{ width: '44px', height: '44px', cursor: 'pointer' }}
                            onClick={() => handleMediaClick(record)}
                        />
                    )}
                    {record.video && (
                        <video
                            src={`data:video/mp4;base64,${record.video}`}
                            style={{ width: '44px', height: '44px', cursor: 'pointer' }}
                            onClick={() => handleMediaClick(record)}
                        />
                    )}
                </div>
            )
        },
        //{
        //    title: 'Equipment',
        //    dataIndex: 'equipNum',
        //    key: 'equipNum',
        //    align: 'center',
        //    render: (text) => <span className="fs-6 fw-bold">{text}</span>
        //},
        {
            title: 'Terminal',
            dataIndex: 'terminal',
            key: 'terminal',
            align: 'center',
            render: (text, record) => <span className="fs-6 fw-bold">{record.offsite ? "Off-site" : text}</span>
        },
        {
            title: 'Line',
            dataIndex: 'lineCode',
            key: 'lineCode',
            align: 'center',
            render: (text) => <span className="fs-6 fw-bold">{text}</span>
        },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
            align: 'center',
            render: (text) => <span className="fs-6 fw-bold">{text}</span>
        },
        //{
        //    title: 'Off-site comment',
        //    dataIndex: 'offsiteComment',
        //    key: 'offsiteComment',
        //    align: 'center',
        //    responsive: ['md'],
        //    render: (text) => <span className="fs-6 fw-bold">{text}</span>
        //},
        {
            title: 'Date',
            dataIndex: 'dateCreated',
            key: 'dateCreated',
            align: 'center',
            responsive: ['lg'],
            render: (text) => <span className="fs-6 fw-bold">{moment(text).format("YYYY-MM-DD HH:mm")}</span>
        },
        {
            title: 'User',
            dataIndex: 'userCode',
            key: 'userCode',
            align: 'center',
            responsive: ['lg'],
            render: (text) => <span className="fs-6 fw-bold">{text}</span>
        },
        {
            title: 'Life#',
            dataIndex: 'lifeNum',
            key: 'lifeNum',
            align: 'center',
            responsive: ['lg'],
            render: (text) => <span className="fs-6 fw-bold">{text}</span>
        },
        //{
        //    title: 'CONFIRMED',
        //    dataIndex: 'confirmed',
        //    key: 'confirmed',
        //    align: 'center',
        //    responsive: ['lg'],
        //    render: (text, record) => <span className="fs-6 fw-bold">{text ? "Yes" : "No"}</span>
        //},  
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <Button type="primary" danger icon={<DeleteOutlined />} onClick={() => showDeleteMediaModal(record)} />
            ),
        },
    ];

    const renderEqPhotoTable = () => {
        return (
            <>
                {/*<IdleTimerProvider
                    timeout={1000 * 60}
                    onPrompt={onIdleTimerProviderPrompt}
                    onIdle={onIdleTimerProviderIdle}
                    onActive={onIdleTimerProviderActive}
                    onAction={onIdleTimerProviderAction} />
                    */}
                <div className="collapsible">
                    <div className="header" {...getToggleProps({
                        onClick: () => setExpanded((prevExpanded) => !prevExpanded),
                    })} >
                        <div className="border border-5 border-secondary bg-secondary" style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                            <Row gutter={[8, 8]} type="flex" justify="space-around" align="middle">
                                <Col xs={0} sm={0} md={0} lg={4} style={{ color: "white", fontWeight: "bold" }} className="fs-5">
                                    <i className={isExpanded ? 'fa fa-minus' : 'fa fa-plus'}></i> PTI Photos
                                </Col>
                                <Col xs={{ span: 1, order: 0 }} sm={{ span: 1, order: 0 }} md={{ span: 1, order: 0 }} lg={0} style={{ color: "white", fontWeight: "bold" }} className="fs-5" align="middle">
                                    <i className={isExpanded ? 'fa fa-minus' : 'fa fa-plus'}></i>
                                </Col>
                                <Col xs={{ span: 15, order: 3, offset: 1 }} sm={{ span: 15, order: 3, offset: 1 }} md={{ span: 15, order: 3, offset: 1 }} lg={{ span: 6, order: 1, offset: 0 }} align="middle">
                                    <Form.Control
                                        type="text"
                                        name="search"
                                        value={filterState.search}
                                        placeholder="Search the grid..."
                                        onClick={(i) => {
                                            i.preventDefault();
                                            i.stopPropagation();
                                        }}
                                        onChange={(i) => {
                                            handleFilters(i, "search");
                                            i.preventDefault();
                                            i.stopPropagation();
                                        }}
                                    />
                                </Col>
                                <Col xs={{ span: 15, order: 1 }} sm={{ span: 15, order: 1 }} md={{ span: 15, order: 1 }} lg={{ span: 6, order: 2 }} align="middle">
                                    <Form.Control
                                        style={{ textTransform: 'uppercase' }}
                                        type="text"
                                        name="equip"
                                        value={loadedEquip}
                                        placeholder="Current equip..."
                                        onClick={(i) => {
                                            i.preventDefault();
                                            i.stopPropagation();
                                        }}
                                        onChange={(i) => {
                                            setLoadedEquip(i.target.value?.toUpperCase());
                                            i.preventDefault();
                                            i.stopPropagation();
                                        }}
                                    />
                                </Col>
                                <Col xs={{ span: 8, order: 2 }} sm={{ span: 8, order: 2 }} md={{ span: 8, order: 2 }} lg={{ span: 4, order: 3 }} align="middle">
                                    {/*<Form.Item>*/}
                                    <Button onClick={handleMediaCapture}
                                        style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center', width: '100%', fontSize: '11pt', fontWeight: 'bold' }}
                                                //style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', fontSize: '15pt', fontWeight: 'bold' }}
                                                disabled={container.trim() ? false : true}
                                                //disabled={qres?.cntr_num?.trim() ? false : true}
                                                className="rounded" icon={<CameraOutlined />}
                                                type="primary">Capture media
                                        </Button>
                                    {/*</Form.Item>*/}
                                    {/*<Button style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center', width: '100%', fontSize: '11pt', fontWeight: 'bold' }}*/}
                                    {/*    type="primary"*/}
                                    {/*    className="rounded"*/}
                                    {/*    icon={<ReloadOutlined />}*/}
                                    {/*    onClick={(i) => {*/}
                                    {/*        i.preventDefault();*/}
                                    {/*        i.stopPropagation();*/}
                                    {/*        //handleReload()*/}
                                    {/*    }}>*/}
                                    {/*    Reload*/}
                                    {/*</Button>*/}
                                </Col>
                                <Col xs={{ span: 8, order: 4 }} sm={{ span: 8, order: 4 }} md={{ span: 8, order: 4 }} lg={{ span: 4, order: 4 }} align="middle">
                                    {/*<Link style={{ color: 'white', textDecoration: 'none' }} disabled={equip ? false : true} to={equip ? `/pti/photo/${equip}` : "/"}>*/}
                                        <Button className="rounded" type="primary"
                                            style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center', width: '100%', fontSize: '11pt', fontWeight: 'bold' }}
                                            icon={<LeftOutlined />}
                                            onClick={backToPti}
                                        >
                                            Back to PTI
                                        </Button>
                                    {/*</Link>*/}
                                </Col>
                            </Row>
                        </div>
                    </div>

                    <div {...getCollapseProps()}>
                        <div className="content">
                            {
                                !state.loading &&
                                <div style={{ paddingTop: '0px' }}>
                                    <div className="border border-5 border-secondary  overflow bg-secondary">
                                        <table>
                                            <tbody>
                                                <Table
                                                    dataSource={state.filteredEqPhotos}
                                                    columns={columns}
                                                    rowClassName={(record) => record.offsite ? 'table-colored-row' : ''}
                                                    //onRow={(record) => ({ onClick: () => handleMediaClick(record) })}
                                                    pagination={false}
                                                />
                                            </tbody>
                                            <tfoot>
                                                <Row gutter={[8, 8]} type="flex" justify="space-around" align="middle" className="border border-5 border-secondary">
                                                    <Col span={3}>
                                                        <Form.Label style={{ fontWeight: 'bold' }}>Terminal</Form.Label>
                                                    </Col>
                                                    <Col span={9}>
                                                        <Form.Select value={filterState.terminal} inline="true" className="fs-7 terminal-select" size="sm" onChange={i => handleFilters(i, "terminal")}>
                                                            <option value="ALL">All Terminals</option>
                                                            {state.terminals.map((terminal) => (<option value={terminal}>{terminal}</option>))}
                                                        </Form.Select>
                                                    </Col>
                                                    <Col span={12}>
                                                    </Col>
                                                </Row>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            }
                            {
                                state.loading === true &&
                                <div className="border border-5 border-secondary  overflow" style={{ borderColor: 'gray', padding: '0px', width: '100%', textAlign: 'center', fontSize: '32px' }}>
                                    <i className="fa fa-spinner fa-spin" style={{ paddingTop: '10px' }}></i>
                                </div>
                            }
                        </div>
                    </div>
                </div >

                <Modal
                    style={{ textTransform: 'uppercase', fontSize: '3em' }}
                    onCancel={handleModalExit}
                    width={640}
                    height={580}
                    visible={modalState.visible}
                    footer={
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div>
                                <Button style={{ display: 'inline-flex', alignItems: 'center', width: '13em' }} icon={<SaveOutlined />}
                                    key="save" disabled={modalWait === true} type="primary" onClick={handleModalSave} shape="round">
                                    Save current media
                                </Button>
                                <Button style={{ display: 'inline-flex', alignItems: 'center', width: '13em' }} icon={<UploadOutlined />}
                                    key="load" disabled={modalWait === true} type="primary" shape="round">
                                    <label htmlFor="fileInput" style={{ marginLeft: '0.6em' }}>
                                        Load from gallery
                                    </label>
                                </Button>
                                <input
                                    type="file"
                                    id="fileInput"
                                    accept="image/*, video/*,.hevc,.3gp"
                                    style={{ display: 'none' }}
                                    onChange={handleFileUpload}
                                    multiple
                                />
                            </div>
                            <div>
                                <Button style={{ display: 'inline-flex', alignItems: 'center', width: '6em' }}
                                    onClick={handleReset}
                                    key="reset" shape="round" icon={<ReloadOutlined />}>
                                    Reset
                                </Button>
                                <Button style={{ display: 'inline-flex', alignItems: 'center', width: '6em' }}
                                    key="exit" type="danger" onClick={handleModalExit} shape="round" icon={<CloseOutlined />}>
                                    Exit
                                </Button>
                            </div>
                            <div style={{ position: 'absolute', transition: 'opacity 1s ease-in-out' }}>
                                {showModalSuccess && <p style={{ fontSize: '9pt', color: 'green' }}>Success! The current media and information have been saved successfully.</p>}
                                {showModalFailure && <p style={{ fontSize: '9pt', color: 'red' }}>Error: Failed to save the current media and information.</p>}
                            </div>
                        </div>
                    }
                    destroyOnClose={true}
                    closable={false}>
                    <div style={{ position: 'relative' }}>
                        <div style={{
                            filter: modalWait ? 'blur(3px)' : 'none',
                            opacity: modalWait ? 0.5 : 1,
                            transition: 'filter 0.3s, opacity 0.3s',
                        }}>
                            <Form layout="vertical">
                                {modalState.takingPhotoOrPreview ? (
                                    <Row justify="center">
                                        <div style={{ border: 'solid', borderWidth: '1px', height: '330px', width: '100%', textAlign: 'center', }}>
                                            <ImageCapture
                                                onCapture={onCapture}
                                                onError={onError}
                                                height={328}
                                                width={437}
                                                className='imgCapture'
                                                userMediaConfig={config}
                                            />
                                        </div>
                                        <div style={{ width: '100%', fontWeight: 'bold', fontSize: '12px' }} className="btn" role="button">
                                            Equipment
                                        </div>
                                    </Row>
                                ) : (
                                    <div>
                                        <Row justify="center">
                                            <div style={{ border: 'solid', borderWidth: '1px', height: '330px', width: '100%', textAlign: 'center', }}>
                                                {modalState.photos.length > 0 ? (
                                                    <img id="imgPhoto" src={modalState.photos[0]} alt="captured-img" style={{ width: '437px', height: '328px' }} />
                                                ) : modalState.videos.length > 0 ? (
                                                    <video id="videoPreview" src={base64ToBlobUrl(modalState.videos[0])} controls style={{ width: '437px', height: '328px' }} />
                                                ) : (
                                                    <div style={{ width: '437px', height: '328px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        No media selected
                                                    </div>
                                                )}
                                            </div>
                                            <div style={{ width: '100%', fontWeight: 'bold', fontSize: '12px' }} className="btn" role="button">
                                                Equipment preview
                                            </div>
                                        </Row>
                                        {(modalState.photos.length > 1 || modalState.videos.length > 0) && (
                                            <Row justify="center" style={{ marginTop: '10px' }}>
                                                <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', width: '100%' }}>
                                                    {modalState.photos.slice(1).map((photo, index) => (
                                                        <div key={`photo-${index}`} style={{ display: 'inline-block', margin: '0 5px' }}>
                                                            <img src={photo} alt={`thumbnail-${index}`} style={{ width: '80px', height: '80px', objectFit: 'cover' }} />
                                                        </div>
                                                    ))}
                                                    {modalState.videos.map((video, index) => (
                                                        <div key={`video-${index}`} style={{ display: 'inline-block', margin: '0 5px', position: 'relative' }}>
                                                            <video src={base64ToBlobUrl(video)} style={{ width: '80px', height: '80px', objectFit: 'cover' }} />
                                                            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white', backgroundColor: 'rgba(0,0,0,0.5)', padding: '2px 5px', borderRadius: '3px' }}>
                                                                ▶
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </Row>
                                        )}
                                    </div>
                                )}
                            </Form>
                        </div>
                        {modalWait && (
                            <div style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '32px',
                                color: 'black',
                            }}>
                                <i className="fa fa-spinner fa-spin"></i>
                            </div>
                        )}
                    </div>
                </Modal>
                
                <Modal style={{ textTransform: 'uppercase', fontSize: '3em' }} width={640} height={480} onCancel={handleViewModalExit} visible={viewModalState.visible}
                    footer={
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Button
                                style={{ display: 'inline-flex', alignItems: 'center', width: '8em' }}
                                key="download"
                                type="primary"
                                onClick={handleViewModalDownload}
                                shape="round"
                                icon={<DownloadOutlined />}
                            >
                                Download
                            </Button>
                            <Button
                                style={{ display: 'inline-flex', alignItems: 'center', width: '6em' }}
                                key="exit"
                                type="danger"
                                onClick={handleViewModalExit}
                                shape="round"
                                icon={<CloseOutlined />}
                            >
                                Exit
                            </Button>
                        </div>
                    }
                    destroyOnClose={true}
                    closable={false}>
                    <Form layout="vertical">
                        <Row justify="center">
                            <div style={{ border: 'solid', borderWidth: '1px', height: '330px', width: '100%', textAlign: 'center' }}>
                                {viewModalState.isVideo ? (
                                    <video
                                        controls
                                        src={viewModalState.video}
                                        style={{ width: '437px', height: '328px' }}
                                    />
                                ) : (
                                    <img
                                        id="imgPhoto"
                                        src={viewModalState.photo}
                                        alt="captured-img"
                                        style={{ width: '437px', height: '328px' }}
                                    />
                                )}
                            </div>
                            <div style={{ width: '100%', fontWeight: 'bold', fontSize: '12px' }} className="btn" role="button">
                                {viewModalState.isVideo ? 'PTI video' : 'PTI picture'}
                            </div>
                        </Row>
                    </Form>
                </Modal>

                <Modal
                    title={`Delete record #${recordToDelete.id}`}
                    visible={visible}
                    onOk={handleConfirmDelete}
                    onCancel={handleCancel}
                    style={{
                        backgroundColor: '#f0f0f0',
                        padding: '8px',
                        borderRadius: '10px',
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
                    }}
                    width={410}
                >
                    {/*<div style={{ textAlign: 'center' }}>*/}
                    {/*    <img src="image.png" alt="Image de confirmation" style={{ width: '50px', height: '50px' }} />*/}
                    {/*</div>*/}
                    <p style={{ textAlign: 'center', fontSize: '16px', fontWeight: 'bold' }}>Do you really want to delete this record?</p>
                    {/*<div style={{ textAlign: 'center' }}>*/}
                    {/*    <Button type="primary" danger icon={<DeleteOutlined />} onClick={handleConfirmDelete}>*/}
                    {/*        Supprimer*/}
                    {/*    </Button>*/}
                    {/*    <Button type="default" icon={<CloseOutlined />} onClick={handleCancel}>*/}
                    {/*        Annuler*/}
                    {/*    </Button>*/}
                    {/*</div>*/}
                </Modal>
                {/*<Modal
                    title="Confirmation de suppression"
                    visible={visible}
                    onOk={handleConfirmDelete}
                    onCancel={handleCancel}
                >
                    <p>Êtes-vous sûr de vouloir supprimer l'enregistrement de {recordToDelete.id} ?</p>
                </Modal>
                */}
            </>
        );
    };

    const updateFilterState = (filter, value) => {
        setFilterState({
            ...filterState,
            [filter]: value
        });
    };

    const handleFilters = (e, filter) => {
        updateFilterState(filter, e.target.value);
    };
 
    const handleMediaClick = (record) => {
        setViewModalState({
            visible: true,
            fileName: getFileName(record.imagePath),
            photo: record.photo ? `data:image/png;base64,${record.photo}` : "",
            video: record.video ? `data:video/mp4;base64,${record.video}` : "",
            isVideo: !!record.video
        });
    };

    const render = () => {
        let contents = renderEqPhotoTable();
        return <div>{contents}</div>;
    };

    const filterData = (item, filter) => {
        if (!filter?.trim())
            return true;

        if (item.equipNum && item.equipNum.toUpperCase().includes(filter.toUpperCase()))
            return true;
        if (item.tag && item.tag.toUpperCase().includes(filter.toUpperCase()))
            return true;
        if (item.lifeNum && item.lifeNum.toUpperCase().includes(filter.toUpperCase()))
            return true;
        if (item.dateCreated && moment(item.dateCreated).format("YYYY-MM-DD HH:mm").toUpperCase().includes(filter.toUpperCase()))
            return true;
        if (item.offsiteComment && item.offsiteComment.toUpperCase().includes(filter.toUpperCase()))
            return true;
        if (item.location && item.location.toUpperCase().includes(filter.toUpperCase()))
            return true;
        if (item.lineCode && item.lineCode.toUpperCase().includes(filter.toUpperCase()))
            return true;
        if (item.terminal && item.terminal.toUpperCase().includes(filter.toUpperCase()))
            return true;
        if (item.id && item.id.toString().includes(filter.toUpperCase()))
            return true;

        return false;
    };

    /*----------------- DELETE MEDIA ------------------------*/
    const [visible, setVisible] = useState(false);
    const [recordToDelete, setRecordToDelete] = useState({});

    const showDeleteMediaModal = (record) => {
        setVisible(true);
        setRecordToDelete(record);
    };

    const handleConfirmDelete = () => {
        // Code pour supprimer l'enregistrement
        _db.deletePtiMedia(recordToDelete.id, (response) => {
            populateEqPhotoData()
        });

        console.log('Enregistrement supprimé :', recordToDelete);
        setVisible(false);
    };

    const handleCancel = () => {
        setVisible(false);
    };
    /*--------------------- FIN DELETE MEDIA -----------------------*/
    
    async function populateEqPhotoData() {
        _db.getPtiPhotos(loadedEquip?.toUpperCase(), (response) => {
            setState({
                eqPhotos: response.data.ptiPhotos,
                filteredEqPhotos: response.data.ptiPhotos.filter(i => loadedEquip === null || loadedEquip === "" || i.equipNum === loadedEquip),
                terminals: response.data.terminals,
                currentUserTerminal: response.data.currentUserTerminal,
                loading: false,
            });
        });
    }

    useEffect(() => {
        if (state.eqPhotos.length > 0) {
            if (firstLoad) {
                setFirstLoad(false);
                if (state.currentUserTerminal !== null && state.currentUserTerminal !== "") {
                    updateFilterState("terminal", state.currentUserTerminal);
                }
            } else {
                updateFilterState();
            }
        }
    }, [state.eqPhotos]);

    useEffect(() => {
        if (firstLoad) {
            populateEqPhotoData();
        } else {
            let filtered = state.eqPhotos;
            if (filterState?.search?.trim())
                filtered = state.eqPhotos.filter((i) => filterData(i, filterState.search));

            if (filterState.terminal !== "ALL") {
                if (filterState.terminal == null || filterState.terminal === "")
                    filtered = filtered.filter(i => !i.terminal)
                else
                    filtered = filtered.filter(i => i.terminal === filterState.terminal);
            }
            if (state.eqPhotos[0]) {
                setState({
                    ...state,
                    filteredEqPhotos: filtered,
                });
            }
        }
    }, [filterState]);

    return render();
};

export default PtiPhoto;