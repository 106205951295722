import agent from "./data-agent";
import authHeader from "./auth-header";
import { notification } from 'antd';

const baseUrl = "/api/";

function getModels() {
    return agent.get(`${baseUrl}getModels`, { headers: authHeader() });
}

function getTerminals() {
    return agent.get(`${baseUrl}getAllTerminals`, { headers: authHeader() });
}

async function getCurrentUserData() {
    return await agent.get(`${baseUrl}getUserData`, { headers: authHeader() });
}

function getCurrentUserTerminal() {
    return agent.get(`${baseUrl}getCurrentUserTerminal`, { headers: authHeader() });
}

/* ------ NOTIFICATION ------*/
const openNotificationWithIcon = (type, description) => {
    notification[type]({
        message: 'Hunt',
        description: `${description}`,
        duration: 4
    });
};
/*----- END NOTIFICATION ---- */

/*----- PTI PHOTOS Service ---- */
const getPtiPhotos = (equipNum, callback) => {
    //console.log('getEqPhotos', authHeader())
    var address = `${baseUrl}PtiPhotos/${equipNum}`;
    //var address = equipNum ? `${API_URL}EquipmentPhotos/${equipNum}` : `${API_URL}AllEquipmentPhotos`;
    return agent.get(address, { headers: authHeader() }).then(
        (response) => {
            callback(response)
        },
        (error) => {
            if (error.response && error.response.status === 401) {
                console.log("SERVICE getPtiPhotos");
                //console.log("SERVICE getEqPhotos logout");
                //EventBus.dispatch("logout");
            }
        }
    );
};

const savePtiMedia = (equipNumber, formData, callback) => {
    return agent.post(`${baseUrl}PtiMedia/${equipNumber}/SaveMedia`, formData, {
        headers: {
            ...authHeader(),
            'Content-Type': 'multipart/form-data'
        }
    }).then(
        (response) => {
            callback(response)
        },
        (error) => {
            if (error.response && error.response.status === 401) {
                console.log("SERVICE saveEquipmentMedia logout", error);
                //EventBus.dispatch("logout");
            }
            else {
                callback(null)
            }
        }
    );
};

const deletePtiMedia = (id, callback) => {
    var url = `${baseUrl}PtiMedia/${id}`;
    return agent.delete(url, { headers: authHeader() }).then(
        (response) => {
            callback(response)
        },
        (error) => {
            if (error.response && error.response.status === 401) {
                console.log("SERVICE getPtiPhotos");
                //console.log("SERVICE getEqPhotos logout");
                //EventBus.dispatch("logout");
            }
        }
    );
};

/*----- END PTI PHOTOS Service ---- */

export default {
    get: agent.get, //axios.get
    post: agent.post, //axios.post,
    put: agent.put, //axios.put
    patch: agent.patch, //axios.patch
    delete: agent.delete, //axios.delete
    openNotificationWithIcon,
    getModels,
    getTerminals,
    getCurrentUserTerminal,
    getCurrentUserData,
    getPtiPhotos,
    savePtiMedia,
    deletePtiMedia
};