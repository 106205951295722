import React, { useEffect, useState, useRef } from "react";
import { useLocation, useHistory } from 'react-router-dom';
import { Row, Col, Button, DatePicker, Input, Select, Modal, Switch } from 'antd';
import moment from "moment";
import authHeader from "../services/auth-header";
import AuthService from "../services/auth.service";
import _db from "../services/data-service";
import 'antd/dist/antd.css';
import 'font-awesome/css/font-awesome.min.css';
import './Dashboard.css';

const monthFormat = 'MMM YYYY'; 

const PTI_NOT_REQUIRED = "PTI Not Required";

const PTI_STATE_TO_DO = "TO DO";
const PTI_STATE_IN_PROGRESS = "IN PROGRESS";
const PTI_STATE_FAILED = "FAILED";
const PTI_STATE_PASSED = "PASSED";

const ptiTypes = [
    { id: "Fuel Only", designation: "Fuel Only" },
    { id: "PTI / Fuel", designation: "PTI / Fuel" },
    { id: "PTI Not Required", designation: "PTI Not Required" },
    { id: "Service", designation: "Service" },
    { id: "REPO", designation: "REPO" }
];

const missingLastInfoReasons = [
    { id: "NOT AVAILABLE", designation: "NOT AVAILABLE" }
];

const noHoursReasons = [
    { id: "HOUR METER BROKEN", designation: "HOUR METER BROKEN" },
    { id: "LOW/DEAD BATTERY", designation: "LOW/DEAD BATTERY" },
    { id: "CONTROLLER DOWN", designation: "CONTROLLER DOWN" },
    { id: "FIRST PTI", designation: "FIRST PTI" },
];

const ptiNotRequiredReasons = [
    { id: "REPO", designation: "REPO" },
    { id: "TRANSFORMER", designation: "TRANSFORMER" },
    { id: "ROLLED BKG", designation: "ROLLED BKG" },
    { id: "TRANSFER", designation: "TRANSFER" },
];

const hoursTypes = [
    { id: "REG", designation: "REG" },
    { id: "OVT", designation: "OVT" },
    { id: "DBL", designation: "DBL" },
];

const gpsHealthCheckReasons = [
    { id: "GPS Damaged", designation: "GPS Damaged" },
    { id: "GPS No response", designation: "GPS No response" },
    { id: "GPS Missing", designation: "GPS Missing" },
    { id: "Not completed", designation: "Not completed" },
];

function isEmptyObject(obj) {
    for (var i in obj) return false;
    return true;

    //return JSON.stringify(obj) === '{}';
}

async function getPutBackItems() {
    let url = "/api/getPutBackItems";
    const { data: response } = await _db.get(url, { headers: authHeader() });
    return response
}

let putBackItems = [];
getPutBackItems().then((items) => { putBackItems = items });

const Pti = () => {
    const location = useLocation();
    const history = useHistory()

    const { Option } = Select;

    let oRecord = { ...location.state?.record } //Original record...
    let oItems = { ...location.state?.items } //Original...

    const { equipNum: container, birthDate, lifeNum, numPhases: phaseNum, line, sTerminal, sLocation } = oRecord;
    let [record, setRecord] = useState({ ...oRecord });

    //console.log("ORecord1", record)
    //console.log("OItems", oItems)
 
    const user = AuthService.getCurrentUser();
    const [onlineUser, setOnlineUser] = useState({ userCode: '', userName: '', password: '', lines: '', class: '', remote: '', terminal: '', allowPti: false });
    const [models, setModels] = useState([]);
    const [services, setServices] = useState([]);
    //const [putBackItems, setPutBackItems] = useState([]); 

    const [model, setModel] = useState(oRecord.model);
    const [serial, setSerial] = useState(oRecord.serial);
    const [year, setYear] = useState(oRecord.year);
    const [putBack, setPutBack] = useState(oRecord.putBack);
    const [currHours, setCurrHours] = useState((oRecord.hours) ? oRecord.hours : oRecord.inHours);
    const [lastPtiHours, setLastPtiHours] = useState(oRecord.lastPtiHours ?? '');
    const [lastPtiDate, setLastPtiDate] = useState(oRecord.lastPtiDate);
    const [lastPmHours, setLastPmHours] = useState(oRecord.lastPmHours ?? '');
    const [lastPmDate, setLastPmDate] = useState(oRecord.lastPmDate);
    const [noHoursReason, setNoHoursReason] = useState(oRecord.noHoursReason);
    const [missingLastInfoReason, setMissingLastInfoReason] = useState(oRecord.missingLastInfoReason); 

    const [ptiType, setPtiType] = useState(oRecord.ptiType ? oRecord.ptiType : oRecord.inPtiType);

    const [canChangePtiType, setCanChangePtiType] = useState(false);
    const [ptiNotRequiredReason, setPtiNotRequiredReason] = useState(oRecord.ptiNotRequiredReason);
    const [hoursType, setHoursType] = useState(oRecord.hoursType ?? "REG");

    const [gpsHealthCheck, setGpsHealthCheck] = useState((oRecord.healthCheck) ? oRecord.healthCheck : oRecord.inGps); 
    const [gpsHealthCheckReason, setGpsHealthCheckReason] = useState(oRecord.gpsHealthCheckReason); 
    const [gpsInstalled, setGpsInstalled] = useState((oRecord.gpsInstalled) ? oRecord.gpsInstalled : oRecord.inGpsInstl);

    const [mountingBolts, setMountingBolts] = useState(oRecord.mountingBolts);   
    const [_91_7228Sub, set_91_7228Sub] = useState(oItems["91-7228sub"]);
    const [b079_718, setB079_718] = useState(oItems["b079-718"]);
    const [b079_719, setB079_719] = useState(oItems["b079-719"]);
    const [b079_720, setB079_720] = useState(oItems["b079-720"]);
    const [oil, setOil] = useState(oItems.oil);
    const [prestone, setPrestone] = useState(oItems.prestone)
    const [ptiComment, setPtiComment] = useState(oRecord.ptiComment);

    const [taskQty, setTaskQty] = useState(''); 
    const [sagePart, setSagePart] = useState('');
    const [taskMin, setTaskMin] = useState('');
    const [taskMax, setTaskMax] = useState('');
    const [routingCode, setRoutingCode] = useState('');
    const [qtyChoice, setQtyChoice] = useState(false);

    const [cancelMode, setCancelMode] = useState(false); //Mode "Cancel" or not 

    //let applied = (record.ptiState !== PTI_STATE_TO_DO)
    const [applied, setApplied] = useState(record.ptiState !== PTI_STATE_TO_DO);
    const edField = ((currHours) && (lastPtiHours)) || (noHoursReason)

    async function populateModels() {
        const { data: response } = await _db.getModels();
        setModels(response);
    }

    async function getUserData() {
        const { data: userData } = await _db.getCurrentUserData();
        setOnlineUser({ ...userData });
    }

    async function getPTIServices(id) {
        let url = "/api/getPTIServices";
        const { data: response } = await _db.get(url, { headers: authHeader(), params: { id: id } });
        setServices(response);
    }

    async function updateServiceQuantity(rCode, quantity) {
        setServices(services.map(service => service.routingCode === rCode ? { ...service, qty: quantity } : service));
        //return true;
    }

    async function getServiceData(rCode) {
        let url = "/api/getServiceData";
        const { data: response } = await _db.get(url, { headers: authHeader(), params: { routingCode: rCode } });
        return (response);
    }

    //async function getPutBackItems() {
    //    let url = "/api/getPutBackItems";
    //    const { data: response } = await _db.get(url, { headers: authHeader() });
    //    //return (response);
    //    setPutBackItems(response); 
    //}

    function unLoadMe() {
        history.push({ pathname: "/pti/list", search: `?t=${sTerminal}&l=${sLocation}` }); //, hash: "#react", //state: {},
    }

    function clearNoReasonFields() {
        setCurrHours('');
        setLastPtiHours('');
        setErrorsHours(oldErrors => ({ ...oldErrors, currHours: '', lastPtiHours: '' }));
    }

    function clearMissingLastInfoReasonFields() {
        setLastPtiDate(null);
        setLastPmHours('');
        setLastPmDate(null);
        setErrorsHours(oldErrors => ({ ...oldErrors, lastPtiDate: '', lastPmHours: '', lastPmDate: '' }));
    }

    async function handleApply() {
        if (!validateHours()) {
            setApplied(false);
            return;
        }
        setApplied(true);
        
        setRecord(oldRecord => {
            return {
                ...oldRecord, /*"ptiState": PTI_STATE_IN_PROGRESS,*/
                "hours": currHours, lastPtiHours, lastPtiDate, lastPmHours, lastPmDate,
                noHoursReason, missingLastInfoReason
            }
        })
    }

    function handleFail() {
        if (!validateModelAndSerial()) { return; }
        handleTPti(PTI_STATE_FAILED)
    }

    function handlePass() {
        handleTPti(PTI_STATE_PASSED)
    }

    function handleSave() {
        handleTPti(PTI_STATE_IN_PROGRESS)
    }

    async function handleTPti(ptiState) {
        if (!validateHours()) { return; }
        if (!validateTPti()) { return; }

        const url = "/api/savetemppti";
        const oData = { "cntrNum": container, birthDate, lifeNum, phaseNum, ptiState, "hours": currHours,   
                    lastPtiHours, lastPtiDate: new Date(lastPtiDate), lastPmHours, lastPmDate: new Date(lastPmDate), noHoursReason, missingLastInfoReason,
                    model, serial: serial?.toUpperCase(), year, putBack, ptiType, ptiNotRequiredReason, hoursType, gpsHealthCheck, gpsHealthCheckReason, 
                        gpsInstalled, mountingBolts, _91_7228Sub, b079_718, b079_719, b079_720, oil, prestone,                        
                        ptiComment, "mecanic": user.userName, line, "terminalCode": sTerminal, "services": JSON.stringify(services) };

        //console.log("savetemppti", oData)

        await _db.post(url, oData, { headers: authHeader() })
            .then((reponse) => {
                _db.openNotificationWithIcon('success', 'Saved succesfully!');
                unLoadMe()
            })
            .catch((error) => {
                //console.log(error)
                _db.openNotificationWithIcon('error', error)
            })
    }

    const handlePtiTask = async (rCode, sChoice) => {
        const { qtyMin: dbMinQ, qtyMax: dbMaxQ } = await getServiceData(rCode); //Get min and max Quantity from DB

        if (sChoice) {
            if (!validateTask(dbMinQ, dbMaxQ)) {
                setLoadingTM(false);
                return;
            }
        }

        const qty = sChoice ? parseInt(taskQty) : parseInt(dbMaxQ);
        updateServiceQuantity(rCode, qty);                  
        if (openTM) handleCancelTM();
    };

    /* -------- TASK MODAL --------- */
    const [loadingTM, setLoadingTM] = useState(false);
    const [openTM, setOpenTM] = useState(false);

    const showTaskModal = (part, rCode, sCode, choice, qMin, qMax) => {        

        if (cancelMode) {
            updateServiceQuantity(rCode, null);
        }
        else {
            setQtyChoice(choice);

            if (choice) {
                setSagePart(part);
                setRoutingCode(rCode);
                setTaskMin(qMin);
                setTaskMax(qMax);

                let qt = services.find((s) => s.routingCode === rCode)
                setTaskQty(() => qt.qty == null ? '' : qt.qty.toString());

                setErrorsTask([]); //supprime toutes les eventuelles erreurs
                setOpenTM(true); //show Modal
            }
            else { //on enregistre directement
                handlePtiTask(rCode, choice)
            }
        }
    };

    const handleOkTM = async (e) => {
        e.preventDefault();
        setLoadingTM(true);

        handlePtiTask(routingCode, qtyChoice);
    }

    const handleCancelTM = () => {
        setLoadingTM(false);
        setOpenTM(false);
        
        setQtyChoice(false);
        setSagePart('');
        setRoutingCode('');
        setTaskMin('');
        setTaskMax('');

        setErrorsTask([]);
    }
    /* ------ END TASK MODAL --------- */

    const serialRef = useRef(null);
    const handleSerialChange = (e) => {
        const position = e.target.selectionStart;
        setSerial(e.target.value.replace(/\s+/g, ''));

        // Répositionner le curseur        
        if (position !== null) {
            serialRef.current.focus();
            serialRef.current.setSelectionRange(position, position);
        }
    };

    //lire parentFolder dans compagny.procFolderPath
    //const parentFolder = 'https://huntrefrigeration.sharepoint.com/:f:/s/Gensetteam/EpZA3aXaekJLnhx5u9ohEuABXr6lr52jeU1bzx0bqIJwvg?e=8QdKYS';
    function openSharepointFolder(type, line) {
        //const folderUrl = `https://huntrefrigeration.sharepoint.com/sites/Gensetteam/Shared%20Documents/Forms/AllItems.aspx?e=5%3Af0f4859b07c742a78ec523aab7df4489&sharingv2=true&fromShare=true&at=9&CID=e0510c88%2D029b%2D4129%2D8c78%2D34ccbd3a3ae3&FolderCTID=0x012000B2933FDB2BD21643A5CDBD3178BCE216&id=%2Fsites%2FGensetteam%2FShared%20Documents%2FGeneral%2FPTI%20FORMS%2F${type}%2F${contener}&viewid=ace3349a%2D6cde%2D4c9f%2D920f%2Ded74d08ccd81`;
        const folderUrl = `https://huntrefrigeration.sharepoint.com/sites/Gensetteam/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FGensetteam%2FShared%20Documents%2FGeneral%2FPTI%20FORMS%2F${type}%2F${line}&viewid=ace3349a%2D6cde%2D4c9f%2D920f%2Ded74d08ccd81`;

        window.open(folderUrl, '_blank');
    }

    function handlePtiPhoto() {
        history.push({
            pathname: `/pti/photo/${container.trim().toUpperCase()}`, // "/pti/photo", //search: "?id=5",hash: "#react",
            //search: `?equip=${container.trim().toLowerCase()}`,
            state: {
                record: {...record },
                items: { ...oItems }
            }
        })
    }

    //const handleSerialKeyDown = (e) => {
    //    if (e.Key === ' ') {
    //        e.preventDefault();
    //    }
    //}

    //Load on startup
    useEffect(() => {
        populateModels();       
        getUserData();
    }, []);

    useEffect(() => {
        getPTIServices(container);
        //getPutBackItems();
    }, []);

    /* ************* HOURS VALIDATION **************** */
    const [errorsHours, setErrorsHours] = useState({ currHours: '', lastPtiHours: '', lastPtiDate: '', lastPmHours: '', lastPmDate : ''});
    const validateHours = () => {
        let valid = true;
        const errorsCopy = { ...errorsHours };

        if ((noHoursReason) && (missingLastInfoReason)) {
            errorsCopy.currHours = '';
            errorsCopy.lastPtiHours = '';
            errorsCopy.lastPtiDate = '';
            errorsCopy.lastPmHours = '';
            errorsCopy.lastPmDate = '';
            setErrorsHours({ ...errorsCopy });
            return true;
        }

        if (!noHoursReason) {
            if (currHours == null || (!currHours.trim())) {
                errorsCopy.currHours = 'Required!';
                valid = false;
            }
            else if (!/^\d*$/.test(currHours)) {
                errorsCopy.currHours = 'Invalid!';
                valid = false;
            }
            else {
                errorsCopy.currHours = '';
            }

            if (lastPtiHours == null || !lastPtiHours.trim()) {
                errorsCopy.lastPtiHours = 'Required!';
                valid = false;
            }
            else if (!/^\d*$/.test(lastPtiHours)) {
                errorsCopy.lastPtiHours = 'Invalid!';
                valid = false;
            }
            else {
                errorsCopy.lastPtiHours = '';
            }

            if (currHours != null && (currHours.trim()) && (lastPtiHours.trim()) && (Number(currHours) <= Number(lastPtiHours))) {
                errorsCopy.currHours = 'Must be greater!';
                valid = false;
            }
        } else {
            errorsCopy.currHours = '';
            errorsCopy.lastPtiHours = '';
        }

        if ((!missingLastInfoReason) && edField) {  //!missingLastInfoReason missingLastInfoReason == null
            if (lastPtiDate == null || isNaN(Date.parse(lastPtiDate))) { //!lastPtiDate.trim()
                errorsCopy.lastPtiDate = 'Required!';
                valid = false;
            }
            else {
                errorsCopy.lastPtiDate = '';
            }

            if (lastPmHours == null || !lastPmHours.trim()) {
                errorsCopy.lastPmHours = 'Required!';
                valid = false;
            }
            else if (!/^\d*$/.test(lastPmHours)) {
                errorsCopy.lastPmHours = 'Invalid!';
                valid = false;
            }
            else {
                errorsCopy.lastPmHours = '';
            }
            
            if (lastPmDate == null || isNaN(Date.parse(lastPmDate))) { 
                errorsCopy.lastPmDate = 'Required!';
                valid = false;
            }
            else {
                errorsCopy.lastPmDate = '';
            }
        }
        else {
            errorsCopy.lastPtiDate = '';
            errorsCopy.lastPmHours = '';
            errorsCopy.lastPmDate = '';
        }

        setErrorsHours(errorsCopy);
        return valid;
    }
    /* ************* HOURS VALIDATION **************** */

    /* ************* TASK VALIDATION **************** */
    const [errorsTask, setErrorsTask] = useState({ taskQty: ''});
    const validateTask = (minQty, maxQty) => {
        let valid = true;
        const errorsCopy = { ...errorsTask };      
        
 
        if ((!taskQty.trim())) {
            errorsCopy.taskQty = 'Required!'; 
            valid = false;
        }
        else if (!/^\d*$/.test(taskQty)) { /*/^-?\d*(\.\d*)?$/ /^[0-9]{1,3}$/ */
            errorsCopy.taskQty = 'Invalid!';
            valid = false;
        }
        else if (minQty == null || maxQty == null) {
            errorsCopy.taskQty = 'Not configured!';
            valid = false;
        }
        else if (parseInt(taskQty) < parseInt(minQty) || parseInt(taskQty) > parseInt(maxQty)) { 
            errorsCopy.taskQty = 'Out of range!';
            valid = false;
        }
        else {
            errorsCopy.taskQty = '';
        }

        setErrorsTask(errorsCopy);
        return valid;
    }
    /* ************* END TASK VALIDATION **************** */

    /* ************* MODEL & SERIAL VALIDATION **************** */
    const [errorsMS, setErrorsMS] = useState({"model": '', "serial": '', "mustCheckHealth": '', "gpsHealthCheckReason": '', "mustInstallGPS": ''  });
    const validateModelAndSerial = () => {
        let valid = true;
        const errorsCopy = { ...errorsMS };

        if (model == null || (!model.trim())) {
            errorsCopy.model = 'Required!';
            valid = false;
        }
        else {
            errorsCopy.model = '';
        }
        
        if (serial == null || (!serial.trim())) {
            errorsCopy.serial = 'Required!';
            valid = false;
        }
        else {
            errorsCopy.serial = '';
        }
     
        //if (record.inGps === 'T' && gpsHealthCheck === 'F' && (gpsHealthCheckReason == null)) {
        //    errorsCopy.mustCheckHealth = 'Required!';
        //    valid = false;
        //}
        //else {
        //    errorsCopy.mustCheckHealth = '';
        //}

        //if (record.inGps === 'T' && gpsHealthCheck === 'F' && (gpsHealthCheckReason == null)) {
        //    errorsCopy.gpsHealthCheckReason = 'Required!';
        //    valid = false;
        //}
        //else {
        //    errorsCopy.gpsHealthCheckReason = '';
        //}

        setErrorsMS(errorsCopy);
        return valid;
    }
    /* ************* END MODEL & SERIAL VALIDATION **************** */

    /* ************* PTI VALIDATION **************** */
    const [errorsPTI, setErrorsPTI] = useState({
        "year": '', "ptiType": '', "ptiNotRequiredReason": '', "_91_7228Sub": '', 
        "b079_718": '', "b079_719": '', "b079_720": '', "oil": '', "prestone": ''
    });
    const validateTPti = () => {
        let valid = true;
        const errorsCopy = { ...errorsPTI };

        if (year == null || (!year.trim()) || (+year === 0)) {
            errorsCopy.year = 'Required!';
            valid = false;
        }
        else if (!/^\d*$/.test(year)) {
            errorsCopy.year = 'Invalid!';
            valid = false;
        }
        else {
            errorsCopy.year = '';
        }

        if (ptiType == null || (!ptiType.trim())) {
            errorsCopy.ptiType = 'Required!';
            valid = false;
        }
        else {
            errorsCopy.ptiType = '';
        }

        if (ptiType === PTI_NOT_REQUIRED && (ptiNotRequiredReason == null)) {
            errorsCopy.ptiNotRequiredReason = 'Required!';
            valid = false;
        }
        else {
            errorsCopy.ptiNotRequiredReason = '';
        }      

        if ((_91_7228Sub != null) && (!/^\d*$/.test(_91_7228Sub))) { /*/^-?\d*(\.\d*)?$/ /^[0-9]{1,3}$/ */
            errorsCopy._91_7228Sub = 'Invalid!';
            valid = false;
        }       
        else {
            errorsCopy._91_7228Sub = '';
        }

        if ((b079_718 != null) && (!/^\d*$/.test(b079_718))) { 
            errorsCopy.b079_718 = 'Invalid!';
            valid = false;
        }
        else {
            errorsCopy.b079_718 = '';
        }

        if ((b079_719 != null) && (!/^\d*$/.test(b079_719))) {
            errorsCopy.b079_719 = 'Invalid!';
            valid = false;
        }
        else {
            errorsCopy.b079_719 = '';
        }

        if ((b079_720 != null) && (!/^\d*$/.test(b079_720))) {
            errorsCopy.b079_720 = 'Invalid!';
            valid = false;
        }
        else {
            errorsCopy.b079_720 = '';
        }

        if ((oil != null) && (!/^\d*$/.test(oil))) {
            errorsCopy.oil = 'Invalid!';
            valid = false;
        }
        else {
            errorsCopy.oil = '';
        }

        if ((prestone != null) && (!/^\d*$/.test(prestone))) {
            errorsCopy.prestone = 'Invalid!';
            valid = false;
        }
        else {
            errorsCopy.prestone = '';
        }

        setErrorsPTI(errorsCopy);
        return valid;
    }
    /* ************* END PTI VALIDATION **************** */
 
    return (
        <>
            <div className="border border-5 border-secondary bg-secondary mb-2" style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                <div className="p-2">             
                    <Row> {/*<Row gutter={[vertical, horesizntal]}>  [16, { xs: 8, sm: 16, md: 24, lg: 32] }*/}
                        <Col md={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }}>
                            <span className="fs-4 fw-bold text-white">PTI</span>
                        </Col>                        
                    </Row>
                </div>
                <div className="bg-white p-2">
                    {
                        (isEmptyObject(record)) ?
                            <Row gutter={[{ xs: 2, sm: 2, md: 2, lg: 8 }, { xs: 6, sm: 6, md: 6, lg: 8 }]} className="">
                                <Col xl={{ span: 8, order: 1 }} lg={{ span: 12, order: 1 }} md={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} xs={{ span: 24, order: 1 }} className="">
                                    <div className="d-flex justify-content-between">
                                        <span>Error</span> 
                                    </div>
                                </Col>
                            </Row>
                            :
                            <>
                                <Row gutter={[{ xs: 2, sm: 2, md: 2, lg: 2 }, { xs: 6, sm: 6, md: 6, lg: 6 }]} className="mb-0"> {/*<Row gutter={[vertical, horesizntal]}>  [16, { xs: 8, sm: 16, md: 24, lg: 32]}*/}
                                    <Col lg={{ span: 6, order: 1 }} md={{ span: 8, order: 2 }} sm={{ span: 24, order: 2 }} xs={{ span: 24, order: 2 }} className="border">
                                        <span className="d-block fs-2 fw-bold text-center">{record.equipNum}</span>
                                    </Col>
                                    <Col lg={{ span: 2, order: 2 }} md={{ span: 3, order: 2 }} sm={{ span: 4, order: 3 }} xs={{ span: 5, order: 3 }}>
                                        {record.holdStatus && <button className="btn btn-danger"><span className="fw-bold"> {record.holdStatus} </span></button>}
                                    </Col>
                                    <Col lg={{ span: 8, order: 3 }} md={{ span: 13, order: 3 }} sm={{ span: 20, order: 4 }} xs={{ span: 19, order: 4 }} className={record.holdStatus && "border"}>
                                        <span className="d-block">{record.comment}</span>
                                    </Col>
                                    <Col lg={{ span: 8, order: 4 }} md={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} xs={{ span: 24, order: 1 }} className="d-flex justify-content-between align-items-stretch">
                                        <div>
                                            <button className={`btn border-2 me-2 ${record.ptiState === PTI_STATE_FAILED ? 'btn-danger rounded-pill fw-semibold' : 'btn-outline-danger rounded'}`} 
                                                    disabled={!applied}
                                                    onClick={handleFail}>
                                                    {record.ptiState === PTI_STATE_FAILED ? "FAILED" : "FAIL"}
                                            </button>
                                            <button className={`btn border-2 me-2 ${record.ptiState === PTI_STATE_PASSED ? 'btn-success rounded-pill fw-semibold' : 'btn-outline-success rounded'}`} 
                                                    disabled={!applied}
                                                    onClick={handlePass}>
                                                    {record.ptiState === PTI_STATE_PASSED ? "PASSED" : "PASS"}
                                            </button>
                                        </div>
                                        <div>
                                            <button className="btn btn-warning rounded me-2" onClick={unLoadMe}>Cancel</button>
                                            <button className="btn btn-success rounded" disabled={!applied} onClick={handleSave}>Save</button>
                                        </div>
                                    </Col>
                                </Row>

                                <div className="border border-1 border-secondary bg-secondary my-2"></div>

                                <Row gutter={[{ xs: 2, sm: 2, md: 4, lg: 12 }, { xs: 8, sm: 6, md: 6, lg: 8 }]} className="mb-2">
                                    <Col xl={{ span: 6, order: 1 }} lg={{ span: 6, order: 1 }} md={{ span: 8, order: 1 }} sm={{ span: 24, order: 1 }} xs={{ span: 24, order: 1 }} className="">
                                        <Row gutter={[0, 0]} className="mb-2">
                                            <Col lg={{ span: 6, order: 1 }} md={{ span: 7, order: 1 }} sm={{ span: 5, order: 1 }} xs={{ span: 5, order: 1 }} className="pt-2">
                                                <span className="fw-bold">MODEL</span>
                                            </Col>
                                            <Col lg={{ span: 18, order: 2 }} md={{ span: 16, order: 2 }} sm={{ span: 19, order: 2 }} xs={{ span: 19, order: 2 }} className="">
                                                <div className={`d-inline ${errorsMS.model ? 'ant-select-error' : ''}`}>
                                                    <Select
                                                        showSearch
                                                        size="large"                                                        
                                                        allowClear={true}
                                                        placeholder="Model"
                                                        name="model"
                                                        value={model}
                                                        className={`w-100 p-0 ${errorsMS.model ? 'is-invalid' : ''}`}
                                                        onChange={value => { setModel(value?.toUpperCase()); }}
                                                    >
                                                        {/*<Option value={undefined}></Option>*/}
                                                        {models.map(item => (<Option key={item.modelCode} value={item.modelCode}>{item.modelCode}</Option>))}
                                                    </Select>
                                                    {errorsMS.model && <div className="invalid-feedback">{errorsMS.model}</div>}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row gutter={[0, 0]} className="mb-2">
                                            <Col lg={{ span: 6, order: 1 }} md={{ span: 7, order: 1 }} sm={{ span: 5, order: 1 }} xs={{ span: 5, order: 1 }} className="pt-2">
                                                <span className="fw-bold">SERIAL</span>
                                            </Col>
                                            <Col lg={{ span: 18, order: 2 }} md={{ span: 16, order: 2 }} sm={{ span: 19, order: 2 }} xs={{ span: 19, order: 2 }} className="">
                                                <Input size="normal" placeholder="Serial"
                                                    name="serial"
                                                    className={`form-control rounded text-uppercase ${errorsMS.serial ? 'is-invalid' : ''}`}
                                                    value={serial}
                                                    ref={serialRef}
                                                    onChange={handleSerialChange}
                                                    //onKeyDown={handleSerialKeyDown}
                                                />
                                                {errorsMS.serial && <div className="invalid-feedback">{errorsMS.serial}</div>}
                                            </Col>
                                        </Row>
                                        <Row gutter={[0, 0]} className="">
                                            <Col lg={{ span: 6, order: 1 }} md={{ span: 7, order: 1 }} sm={{ span: 5, order: 1 }} xs={{ span: 5, order: 1 }} className="pt-2">
                                                <span className="fw-bold">YEAR</span>
                                            </Col>
                                            <Col lg={{ span: 18, order: 2 }} md={{ span: 16, order: 2 }} sm={{ span: 19, order: 2 }} xs={{ span: 19, order: 2 }} className="">
                                                <Input size="normal" placeholder="Year"
                                                    name="year"
                                                    maxLength={4}
                                                    className={`form-control rounded text-end ${errorsPTI.year ? 'is-invalid' : ''}`}
                                                    disabled={(record.year && +record.year !== 0)}
                                                    value={year}
                                                    onChange={(e) => setYear(e.target.value)}
                                                />
                                                {errorsPTI.year && <div className="invalid-feedback">{errorsPTI.year}</div>}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xl={{ span: 10, order: 2 }} lg={{ span: 10, order: 2 }} md={{ span: 16, order: 1 }} sm={{ span: 24, order: 1 }} xs={{ span: 24, order: 1 }} className="border">
                                        <span className="d-block">{record.notes}</span>
                                    </Col>
                                    <Col xl={{ span: 8, order: 3 }} lg={{ span: 8, order: 2 }} md={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} xs={{ span: 24, order: 1 }} className="align-self-end">                                        
                                        <Row gutter={[0, 0]} className="d-flex justify-content-end align-items-center">
                                            <Col xl={{ span: 6, order: 1 }} lg={{ span: 24, order: 1 }} md={{ span: 3, order: 1 }} sm={{ span: 5, order: 1 }} xs={{ span: 5, order: 1 }} className="pt-1">
                                                <span className="fw-bold">PUT BACK</span>
                                            </Col>
                                            <Col xl={{ span: 18, order: 2 }} lg={{ span: 24, order: 2 }} md={{ span: 10, order: 2 }} sm={{ span: 19, order: 2 }} xs={{ span: 19, order: 2 }} className="">
                                                <div className={`d-inline ${errorsMS.putBack ? 'ant-select-error' : ''}`}>
                                                    <Select
                                                        showSearch
                                                        size="large"
                                                        allowClear={true}
                                                        placeholder="Put Back"
                                                        name="putBack"
                                                        value={putBack}
                                                        className={`w-100 p-0 ${errorsMS.putBack ? 'is-invalid' : ''}`}
                                                        onChange={value => { setPutBack(value); }}
                                                    >
                                                        {/*<Option value={undefined}></Option>*/}
                                                        {putBackItems.map(item => (<Option key={item.value} value={item.value}>{item.description}</Option>))}
                                                    </Select>
                                                    {errorsMS.putBack && <div className="invalid-feedback">{errorsMS.putBack}</div>}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>                                    
                                </Row>

                                <div className="border border-1 border-secondary bg-secondary my-2"></div>
                                <Row gutter={[{ xs: 2, sm: 2, md: 2, lg: 8 }, { xs: 8, sm: 6, md: 6, lg: 8 }]} className="mb-2">
                                    <Col lg={{ span: 2, order: 1 }} md={{ span: 2, order: 1 }} sm={{ span: 24, order: 1 }} xs={{ span: 24, order: 1 }} className="d-flex justify-content-start align-items-center">
                                        <span className="fw-bold">HOURS</span>
                                    </Col>
                                    <Col lg={{ span: 20, order: 2 }} md={{ span: 19, order: 2 }} sm={{ span: 20, order: 2 }} xs={{ span: 24, order: 2 }} className="border">
                                        <Row type="flex" justify="space-between" style={{ margin: '7px' }}>
                                            <Col className="fs-7 mb-2">
                                                <span className="fw-bold d-block">Current hours</span>
                                                <Input size="normal" style={{ width: 145, textAlign: 'right' }}
                                                    name="currHours"
                                                    className={`form-control ${errorsHours.currHours ? 'is-invalid' : ''}`}
                                                    value={currHours}
                                                    onChange={(e) => {
                                                        setCurrHours(e.target.value);
                                                        if (e.target.value && lastPtiHours) setNoHoursReason(null);
                                                        }
                                                    }
                                                />
                                                {errorsHours.currHours && <div className="invalid-feedback">{errorsHours.currHours}</div>}
                                            </Col>
                                            <Col className="fs-7 mb-2">
                                                <span className="fw-bold d-block">Last PTI hours</span>
                                                <Input size="normal" style={{ width: 145, textAlign: 'right' }}
                                                    name="lastPtiHours"
                                                    className={`form-control ${errorsHours.lastPtiHours ? 'is-invalid' : ''}`}
                                                    value={lastPtiHours}
                                                    onChange={(e) => {
                                                        setLastPtiHours(e.target.value);
                                                        if (e.target.value && currHours) setNoHoursReason(null);
                                                        }
                                                    }
                                                />
                                                {errorsHours.lastPtiHours && <div className="invalid-feedback">{errorsHours.lastPtiHours}</div>}
                                            </Col>
                                            <Col className="fs-7 mb-2">
                                                <span className="fw-bold d-block">Last PTI date</span>
                                                <DatePicker
                                                    picker="month"
                                                    format={monthFormat} 
                                                    placeholder=""
                                                    name="lastPtiDate"
                                                    className={`form-control ${errorsHours.lastPtiDate ? 'is-invalid' : ''}`}                                                    
                                                    disabled={!edField}
                                                    value={isNaN(Date.parse(lastPtiDate)) ? '' : moment(lastPtiDate)}
                                                    //value={lastPtiDate == null ? '' : moment(lastPtiDate)}  
                                                    onChange={(date, dateString) => {
                                                            setLastPtiDate(dateString != null ? moment(dateString, monthFormat) : null);
                                                            //setLastPtiDate(dateString)
                                                            if (dateString != null && lastPmHours && lastPmDate) setMissingLastInfoReason(null);
                                                        }
                                                    }
                                                />
                                                {errorsHours.lastPtiDate && <div className="invalid-feedback">{errorsHours.lastPtiDate}</div>}
                                            </Col>
                                            <Col className="fs-7 mb-2">
                                                <span className="fw-bold d-block">Last PM hours</span>
                                                <Input size="normal" style={{ width: 145, textAlign: 'right' }}
                                                    name="lastPmHours"
                                                    className={`form-control ${errorsHours.lastPmHours ? 'is-invalid' : ''}`}
                                                    disabled={!edField}
                                                    value={lastPmHours}
                                                    onChange={(e) => {
                                                        setLastPmHours(e.target.value);
                                                        if (lastPtiDate && e.target.value && lastPmDate) setMissingLastInfoReason(null);
                                                    }}
                                                />
                                                {errorsHours.lastPmHours && <div className="invalid-feedback">{errorsHours.lastPmHours}</div>}
                                            </Col>
                                            <Col className="fs-7">
                                                <span className="fw-bold d-block">Last PM date</span>
                                                <DatePicker
                                                    picker="month"
                                                    format={monthFormat} 
                                                    placeholder=""
                                                    name="lastPmDate"
                                                    className={`form-control ${errorsHours.lastPmDate ? 'is-invalid' : ''}`}
                                                    disabled={!edField}
                                                    value={isNaN(Date.parse(lastPmDate)) ? '' : moment(lastPmDate)}
                                                    //value={lastPmDate == null ? '' : moment(lastPmDate)}
                                                    onChange={(date, dateString) => {
                                                            setLastPmDate(dateString != null ? moment(dateString, monthFormat) : null);
                                                            //setLastPmDate(dateString);
                                                            if (lastPtiDate && lastPmHours && dateString != null) setMissingLastInfoReason(null);
                                                        }
                                                    }
                                                />
                                                {errorsHours.lastPmDate && <div className="invalid-feedback">{errorsHours.lastPmDate}</div>}
                                            </Col>
                                        </Row>
                                    </Col> 
                                    <Col lg={{ span: 2, order: 3 }} md={{ span: 3, order: 3 }} sm={{ span: 4, order: 3 }} xs={{ span: 24, order: 3 }} className="d-flex justify-content-center align-items-center">
                                        <button className="btn btn-warning rounded text-white"
                                            disabled={false} //applied
                                            onClick={handleApply}>
                                            APPLY
                                        </button>
                                    </Col>
                                </Row>
                                <Row gutter={[{ xs: 2, sm: 2, md: 2, lg: 8 }, { xs: 2, sm: 6, md: 6, lg: 8 }]} className="mb-2">
                                    <Col lg={{ span: 4, order: 1 }} md={{ span: 7, order: 1 }} sm={{ span: 24, order: 1 }} xs={{ span: 24, order: 1 }} className="d-flex justify-content-start align-items-center">
                                        <div className="fw-bold">NO HOURS REASON</div>
                                    </Col>
                                    <Col lg={{ span: 6, order: 2 }} md={{ span: 11, order: 2 }} sm={{ span: 15, order: 2 }} xs={{ span: 24, order: 2 }} className="">
                                        <div className="d-inline">
                                            <Select
                                                size="large"
                                                showSearch
                                                allowClear={true}
                                                //disabled={applied}
                                                placeholder=""
                                                name="noHoursReason"
                                                className="w-100"
                                                value={noHoursReason}
                                                onChange={value => { setNoHoursReason(value); if (value != null) clearNoReasonFields(); }}                                                
                                            >
                                                {/*<Option value={undefined}></Option>*/}
                                                {noHoursReasons.map(item => (<Option key={item.id} value={item.designation}>{item.designation}</Option>))}
                                            </Select>
                                        </div>
                                    </Col>
                                    <Col lg={{ span: 6, order: 3, offset: 2 }} md={{ span: 7, order: 3 }} sm={{ span: 24, order: 3 }} xs={{ span: 24, order: 3 }} className="d-flex justify-content-start justify-content-lg-end align-items-center">
                                        <span className="d-block no-wrap fw-bold">MISSING LAST INFO REASON</span>
                                    </Col>
                                    <Col lg={{ span: 6, order: 4 }} md={{ span: 11, order: 4 }} sm={{ span: 15, order: 4 }} xs={{ span: 24, order: 4 }} className="">
                                        <div className="d-inline"> 
                                            <Select
                                                size="large"
                                                showSearch
                                                allowClear={true}
                                                //disabled={applied}
                                                placeholder=""
                                                name="missingLastInfoReason"
                                                className="w-100"
                                                value={missingLastInfoReason}
                                                onChange={value => { setMissingLastInfoReason(value); if (value != null) clearMissingLastInfoReasonFields(); }}                                                
                                            >
                                                {/*<Option value={undefined}></Option>*/}
                                                {missingLastInfoReasons.map(item => (<Option key={item.id} value={item.designation}>{item.designation}</Option>))}
                                            </Select>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="border border-1 border-secondary bg-secondary my-2"></div>
                                <Row gutter={[{ xs: 2, sm: 2, md: 2, lg: 8 }, { xs: 6, sm: 6, md: 6, lg: 8 }]} className="mb-2">
                                    <Col lg={{ span: 4, order: 1 }} md={{ span: 3, order: 1 }} sm={{ span: 4, order: 1 }} xs={{ span: 6, order: 1 }} className="">
                                        <button className="w-100 fs-6 btn btn-outline-secondary" disabled={!onlineUser.allowPti} onClick={() => setCanChangePtiType(true)}>Override <i className="fa fa-refresh"></i></button> {/**/}
                                    </Col>
                                    <Col lg={{ span: 6, order: 2 }} md={{ span: 11, order: 2 }} sm={{ span: 20, order: 2 }} xs={{ span: 18, order: 2 }} className="d-flex justify-content-end align-items-center">
                                        <span className="px-2 fw-bold">PTI TYPE</span>
                                        <div className={`d-inline ${errorsPTI.ptiType ? 'ant-select-error' : ''}`}> 
                                            <Select
                                                showSearch
                                                size="large"                                                
                                                allowClear={true}
                                                disabled={!canChangePtiType}
                                                style={{ width: 180 }}
                                                placeholder=""
                                                name="ptiType"                                                
                                                className={`${errorsPTI.ptiType ? 'is-invalid' : ''}`}
                                                value={ptiType}
                                                onChange={value => { setPtiType(value); }}                                                
                                            >
                                                {/*<Option value={undefined}></Option>*/}
                                                {ptiTypes.map(item => (<Option key={item.id} value={item.designation}>{item.designation}</Option>))}
                                            </Select>
                                            {errorsPTI.ptiType && <div className="invalid-feedback">{errorsPTI.ptiType}</div>}
                                        </div>
                                    </Col>
                                    <Col lg={{ span: 8, order: 3 }} md={{ span: 10, order: 3 }} sm={{ span: 12, order: 3 }} xs={{ span: 24, order: 3 }} className="d-flex justify-content-end align-items-center">
                                        <span className="pe-2 fw-bold">REASON</span>
                                        <div className={`d-inline ${errorsPTI.ptiNotRequiredReason ? 'ant-select-error' : ''}`}> 
                                            <Select
                                                showSearch
                                                size="large"   
                                                allowClear={true}
                                                style={{ width: 180 }}
                                                placeholder=""
                                                name="ptiNotRequiredReason"
                                                className={`p-0 ${errorsPTI.ptiNotRequiredReason ? 'is-invalid' : ''}`}
                                                value={ptiNotRequiredReason}
                                                onChange={value => { setPtiNotRequiredReason(value); }}                                                
                                            >
                                                {/*<Option value={undefined}></Option>*/}
                                                {ptiNotRequiredReasons.map(item => (<Option key={item.id} value={item.designation}>{item.designation}</Option>))}
                                            </Select>
                                            {errorsPTI.ptiNotRequiredReason && <div className="invalid-feedback">{errorsPTI.ptiNotRequiredReason}</div>}
                                        </div>
                                    </Col>
                                    <Col lg={{ span: 6, order: 4 }} md={{ span: 24, order: 4 }} sm={{ span: 12, order: 4 }} xs={{ span: 24, order: 4 }} className="d-flex justify-content-end align-items-center">
                                        <span className="pe-2 fw-bold">HOURS TYPE</span>
                                        <div className="d-inline"> 
                                            <Select
                                                allowClear
                                                showSearch
                                                size="large"                                                
                                                style={{ width: 90 }}
                                                name="hoursType"
                                                value={hoursType ?? "REG"}
                                                onChange={value => { setHoursType(value); }}                                                
                                            >
                                                {/*<Option value={undefined}></Option>*/}
                                                {hoursTypes.map(item => (<Option key={item.id} value={item.designation}>{item.designation}</Option>))}
                                            </Select>
                                        </div>
                                    </Col>
                                </Row>
                                <Row gutter={[{ xs: 2, sm: 2, md: 2, lg: 8 }, { xs: 6, sm: 6, md: 6, lg: 8 }]} className="mb-2 d-flex justify-content-center align-items-center">
                                    <Col lg={{ span: 5, order: 1 }} md={{ span: 8, order: 1 }} sm={{ span: 12, order: 1 }} xs={{ span: 12, order: 1 }} className="">
                                        <div>
                                            <span className="fw-bold me-2">GPS HEALTH CHECK</span>   
                                            <Switch                                                
                                                className={`${gpsHealthCheck === 'T' ? 'bg-success' : 'bg-secondary'}`} //style={{ backgroundColor: gpsHealthCheck === 'T' ? 'bg-success' : "bg-secondary" }}
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                checked={ gpsHealthCheck === 'T' }
                                                onChange={(checked) => { (checked) ? setGpsHealthCheck('T') : setGpsHealthCheck('F') }}
                                                //disabled={ record.inGps === 'F'}
                                            />
                                            {/*<span className={`d-none form-control text-end ${errorsPTI.mustCheckHealth ? 'is-invalid' : ''}`}></span>                                            */}
                                            {/*{errorsPTI.mustCheckHealth && <div className="invalid-feedback">{errorsPTI.mustCheckHealth}</div>}*/}
                                        </div>
                                    </Col>
                                    <Col lg={{ span: 5, order: 2 }} md={{ span: 8, order: 2 }} sm={{ span: 12, order: 2 }} xs={{ span: 12, order: 2 }} className="">
                                        <div className={`pe-1 ${errorsPTI.gpsHealthCheckReason ? 'ant-select-error' : ''}`}>
                                            <Select
                                                showSearch
                                                size="large"                                                
                                                allowClear={true}
                                                placeholder=""
                                                name="gpsHealthCheckReason"
                                                className={`w-100 ms-1 ${errorsPTI.gpsHealthCheckReason ? 'is-invalid' : ''}`}
                                                value={gpsHealthCheckReason}
                                                onChange={value => { setGpsHealthCheckReason(value); }}                                                
                                            >
                                                {/*<Option value={undefined}></Option>*/}
                                                {gpsHealthCheckReasons.map(item => (<Option key={item.id} value={item.designation}>{item.designation}</Option>))}
                                            </Select>
                                            {errorsPTI.gpsHealthCheckReason && <div className="invalid-feedback">{errorsPTI.gpsHealthCheckReason}</div>}
                                        </div>
                                    </Col>
                                    <Col lg={{ span: 5, order: 3 }} md={{ span: 8, order: 3 }} sm={{ span: 24, order: 3 }} xs={{ span: 24, order: 3 }} className="text-center text-sm-end small">
                                        {(record.inGps === 'T') && <Button type="primary" danger>MUST CHECK GPS HEALTH</Button>} 
                                    </Col>
                                    <Col lg={{ span: 5, order: 4 }} md={{ span: 12, order: 4 }} sm={{ span: 12, order: 4 }} xs={{ span: 12, order: 4 }} className="">
                                        <div className="text-lg-end">
                                            <span className="fw-bold me-2">GPS INSTALLED</span>
                                            <Switch                                                
                                                className={gpsInstalled === 'T' ? 'bg-success' : 'bg-secondary'} 
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                checked={gpsInstalled === 'T'}
                                                onChange={(checked) => { (checked) ? setGpsInstalled('T') : setGpsInstalled('F') }}
                                                //disabled={record.inGpsInstl === 'F'}
                                            />                                           
                                        </div>
                                    </Col>
                                    <Col lg={{ span: 4, order: 5 }} md={{ span: 12, order: 5 }} sm={{ span: 12, order: 5 }} xs={{ span: 12, order: 5 }} className="text-end">
                                        <div className="">
                                            {(record.inGpsInstl === 'T') && <Button type="primary" danger>MUST INSTALL GPS</Button>} 
                                        </div>
                                    </Col>
                                </Row>
                                <div className="border border-1 border-secondary bg-secondary my-2"></div>

                                <Row gutter={[{ xs: 2, sm: 2, md: 2, lg: 8 }, { xs: 6, sm: 6, md: 6, lg: 8 }]} className="mb-2">
                                    <Col lg={{ span: 24, order: 4 }} md={{ span: 24, order: 4 }} sm={{ span: 24, order: 4 }} xs={{ span: 24, order: 4 }} className="">
                                        <div className="text-center">
                                            <span className="fw-bold me-2">Cancel mode</span>
                                            <Switch
                                                checkedChildren="ON"
                                                unCheckedChildren="OFF"
                                                checked={cancelMode}
                                                onChange={(checked) => setCancelMode(checked)}
                                            />
                                        </div>
                                    </Col>

                                </Row>
                                <div className="border border-1 border-secondary bg-secondary my-2"></div>

                                {/*------------------------------------------*/}
                                <Row gutter={[{ xs: 2, sm: 2, md: 2, lg: 8 }, { xs: 6, sm: 6, md: 6, lg: 8 }]} className="mb-2">
                                    <Col xl={{ span: 8, order: 1 }} lg={{ span: 12, order: 1 }} md={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} xs={{ span: 24, order: 1 }} className="">
                                        <div className="d-flex justify-content-between">
                                            <span>Task</span>
                                            <span>Part(Quantity)</span>
                                        </div>
                                    </Col>
                                    <Col xl={{ span: 8, order: 2 }} lg={{ span: 12, order: 2 }} md={{ span: 24, order: 2 }} sm={{ span: 12, order: 2 }} xs={{ span: 8, order: 2 }} className="d-none d-lg-block">
                                        <div className="d-flex justify-content-between">
                                            <span>Task</span>
                                            <span>Part(Quantity)</span>
                                        </div>
                                    </Col>
                                    <Col xl={{ span: 8, order: 3 }} lg={{ span: 12, order: 3 }} md={{ span: 24, order: 3 }} sm={{ span: 12, order: 3 }} xs={{ span: 8, order: 3 }} className="d-none d-xl-block">
                                        <div className="d-flex justify-content-between">
                                            <span>Task</span>
                                            <span>Part(Quantity)</span>
                                        </div>
                                    </Col>
                                </Row>
                                {/*------------------------------------------*/}
                                <Row gutter={[{ xs: 2, sm: 2, md: 2, lg: 8 }, { xs: 6, sm: 6, md: 6, lg: 8 }]} className="mb-2">
                                    {
                                        services.map(service => (
                                            <Col key={service.routingCode} xl={{ span: 8, order: `${service.buttonNum}` }} lg={{ span: 12, order: `${service.buttonNum}` }} md={{ span: 24, order: `${service.buttonNum}` }} sm={{ span: 24, order: `${service.buttonNum}` }} xs={{ span: 24, order: `${service.buttonNum}` }} className="">
                                                <div className="d-flex justify-content-between align-items-start">
                                                    <Button className={`w-75 me-2 text-truncate ${service.qty != null ? 'btn-success' : 'btn-primary'}`} title={service.longDesc} key={service.routingCode} type="primary">
                                                        <span className="d-block text-truncate">{service.shortDesc}</span>
                                                    </Button>
                                                    <Button className={`w-25 text-truncate ${service.qty != null ? 'btn-success' : 'btn-primary'}`}
                                                            title={`${service.sagePart}${service.qty != null ? '(' + service.qty + ')' : ''}`}
                                                            disabled={!applied}
                                                            onClick={() => showTaskModal(service.sagePart, service.routingCode, service.sageCode, service.qtyChoice, service.qtyMin, service.qtyMax)}>  
                                                        <span className="d-block text-truncate">{`${service.sagePart}${service.qty != null ? '(' + service.qty + ')' : ''}`}</span>
                                                    </Button>
                                                </div>
                                            </Col>
                                        ))
                                    }                                   
                                </Row>
                                <div className="border border-1 border-secondary bg-secondary my-2"></div>
                                <Row gutter={[{ xs: 2, sm: 2, md: 2, lg: 8 }, { xs: 6, sm: 6, md: 6, lg: 8 }]} className="mb-2">
                                    <Col xl={{ span: 5, order: 1 }} lg={{ span: 6, order: 1 }} md={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} xs={{ span: 24, order: 1 }} className="">
                                        <span className="fw-bold me-2">536 - MOUNTING BOLTS</span>
                                        <Switch
                                            checkedChildren="Yes"
                                            unCheckedChildren="No"
                                            checked={mountingBolts}
                                            onChange={(checked) => { setMountingBolts(checked) }}
                                        />
                                    </Col>
                                    <Col xl={{ span: 19, order: 2 }} lg={{ span: 18, order: 2 }} md={{ span: 24, order: 2 }} sm={{ span: 24, order: 2 }} xs={{ span: 24, order: 2 }} className="">
                                        <Row gutter={[{ xs: 2, sm: 2, md: 2, lg: 8 }, { xs: 6, sm: 6, md: 6, lg: 8 }]} className="mb-2">
                                            <Col xl={{ span: 8, order: 1 }} lg={{ span: 12, order: 1 }} md={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} xs={{ span: 24, order: 1 }} className="">
                                                <div className="d-flex justify-content-between">
                                                    <span>Part</span>
                                                    <span>Quantity</span>
                                                </div>
                                            </Col>
                                            <Col xl={{ span: 8, order: 2 }} lg={{ span: 12, order: 2 }} md={{ span: 24, order: 2 }} sm={{ span: 12, order: 2 }} xs={{ span: 8, order: 2 }} className="d-none d-lg-block">
                                                <div className="d-flex justify-content-between">
                                                    <span>Part</span>
                                                    <span>Quantity</span>
                                                </div>
                                            </Col>
                                            <Col xl={{ span: 8, order: 3 }} lg={{ span: 12, order: 3 }} md={{ span: 24, order: 3 }} sm={{ span: 12, order: 3 }} xs={{ span: 8, order: 3 }} className="d-none d-xl-block">
                                                <div className="d-flex justify-content-between">
                                                    <span>Part</span>
                                                    <span>Quantity</span>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row gutter={[{ xs: 2, sm: 2, md: 2, lg: 8 }, { xs: 6, sm: 6, md: 6, lg: 8 }]} className="mb-2">
                                            <Col xl={{ span: 8, order: 1 }} lg={{ span: 12, order: 1 }} md={{ span: 8, order: 1 }} sm={{ span: 12, order: 1 }} xs={{ span: 24, order: 1 }} className="">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <label className="w-75 w-sm-50 fw-bold text-sm-end me-2" title="91-7228SUB">PLATE</label>
                                                    <div className="w-25 w-sm-50">
                                                        <Input size="normal" 
                                                            name="_91_7228Sub"
                                                            className={`form-control text-end ${errorsPTI._91_7228Sub ? 'is-invalid' : ''}`}                                                            
                                                            disabled={!edField}
                                                            maxLength={10}
                                                            value={_91_7228Sub}
                                                            onChange={(e) => set_91_7228Sub(e.target.value !== '' ? e.target.value : null)}
                                                        />
                                                        {errorsPTI._91_7228Sub && <div className="invalid-feedback">{errorsPTI._91_7228Sub}</div>}
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xl={{ span: 8, order: 4 }} lg={{ span: 12, order: 6 }} md={{ span: 8, order: 4 }} sm={{ span: 12, order: 6 }} xs={{ span: 24, order: 2 }} className="">
                                                {/*<div className="d-flex justify-content-between align-items-center">*/}
                                                {/*    <label className="w-75 fw-bold text-sm-end me-2">B079-718</label>*/}
                                                {/*    <div className="w-25">*/}
                                                {/*        <Input size="normal" */}
                                                {/*            name="b079_718"*/}
                                                {/*            className={`form-control text-end ${errorsPTI.b079_718 ? 'is-invalid' : ''}`}*/}
                                                {/*            disabled={!edField}*/}
                                                {/*            value={b079_718}*/}
                                                {/*            onChange={(e) => setB079_718(e.target.value !== '' ? e.target.value : null)}*/}
                                                {/*        />*/}
                                                {/*        {errorsPTI.b079_718 && <div className="invalid-feedback">{errorsPTI.b079_718}</div>}*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                            </Col>
                                            <Col xl={{ span: 8, order: 2 }} lg={{ span: 12, order: 3 }} md={{ span: 8, order: 2 }} sm={{ span: 12, order: 3 }} xs={{ span: 24, order: 3 }} className="">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <label className="w-75 w-sm-50 fw-bold text-sm-end me-2" title="B079-719">SHORT BOLT</label>
                                                    <div className="w-25 w-sm-50">
                                                        <Input size="normal"
                                                            name="b079_719"
                                                            className={`form-control text-end ${errorsPTI.b079_719 ? 'is-invalid' : ''}`}
                                                            disabled={!edField}
                                                            maxLength={10}
                                                            value={b079_719}
                                                            onChange={(e) => setB079_719(e.target.value !== '' ? e.target.value : null)}
                                                        />
                                                        {errorsPTI.b079_719 && <div className="invalid-feedback">{errorsPTI.b079_719}</div>}
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xl={{ span: 8, order: 5 }} lg={{ span: 12, order: 5 }} md={{ span: 8, order: 5 }} sm={{ span: 12, order: 5 }} xs={{ span: 24, order: 4 }} className="">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <label className="w-75 w-sm-50 fw-bold text-sm-end me-2" title="B079-720">LONG BOLT</label>
                                                    <div className="w-25 w-sm-50">
                                                        <Input size="normal"
                                                            name="b079_720"
                                                            className={`form-control text-end ${errorsPTI.b079_720 ? 'is-invalid' : ''}`}
                                                            disabled={!edField}
                                                            maxLength={10}
                                                            value={b079_720}
                                                            onChange={(e) => setB079_720(e.target.value !== '' ? e.target.value : null)}
                                                        />
                                                        {errorsPTI.b079_720 && <div className="invalid-feedback">{errorsPTI.b079_720}</div>}
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xl={{ span: 8, order: 3 }} lg={{ span: 12, order: 2 }} md={{ span: 8, order: 3 }} sm={{ span: 12, order: 2 }} xs={{ span: 24, order: 5 }} className="">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <label className="w-75 w-sm-50 fw-bold text-sm-end me-2" title="Oil">OIL</label>
                                                    <div className="w-25 w-sm-50">
                                                        <Input size="normal"
                                                            name="oil"
                                                            className={`form-control text-end ${errorsPTI.oil ? 'is-invalid' : ''}`}
                                                            disabled={!edField}
                                                            maxLength={10}
                                                            value={oil}
                                                            onChange={(e) => setOil(e.target.value !== '' ? e.target.value : null)}
                                                        />
                                                        {errorsPTI.oil && <div className="invalid-feedback">{errorsPTI.oil}</div>}
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xl={{ span: 8, order: 6 }} lg={{ span: 12, order: 4 }} md={{ span: 8, order: 6 }} sm={{ span: 12, order: 4 }} xs={{ span: 24, order: 6 }} className="">
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <label className="w-75 w-sm-50 fw-bold text-sm-end me-2" title="Prestone">PRESTONE</label>
                                                    <div className="w-25 w-sm-50">
                                                        <Input size="normal"
                                                            name="prestone"
                                                            className={`form-control text-end ${errorsPTI.prestone ? 'is-invalid' : ''}`}
                                                            disabled={!edField}
                                                            maxLength={10}
                                                            value={prestone}
                                                            onChange={(e) => setPrestone(e.target.value !== '' ? e.target.value : null)}
                                                        />
                                                        {errorsPTI.prestone && <div className="invalid-feedback">{errorsPTI.prestone}</div>}
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <div className="border border-1 border-secondary bg-secondary my-2"></div>
                                <Row gutter={[{ xs: 2, sm: 2, md: 2, lg: 8 }, { xs: 6, sm: 6, md: 6, lg: 8 }]} className="">
                                    <Col xl={{ span: 2, order: 1 }} lg={{ span: 2, order: 1 }} md={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} xs={{ span: 24, order: 1 }} className="d-flex justify-content-between align-items-center">
                                        <span className="">Comment</span>
                                    </Col>
                                    <Col xl={{ span: 18, order: 2 }} lg={{ span: 17, order: 2 }} md={{ span: 24, order: 2 }} sm={{ span: 24, order: 2 }} xs={{ span: 24, order: 2 }} className="">
                                        <textarea rows={2} name="ptiComment"
                                            className="form-control"
                                            value={ptiComment || ''}
                                            onChange={(e) => setPtiComment(e.target.value)}
                                            >
                                        </textarea>
                                    </Col>
                                    <Col xl={{ span: 4, order: 3 }} lg={{ span: 5, order: 3 }} md={{ span: 24, order: 3 }} sm={{ span: 24, order: 3 }} xs={{ span: 24, order: 3 }} className="d-flex justify-content-between align-items-center">
                                        <Button className="btn-primary" type="primary"
                                                onClick={handlePtiPhoto}
                                                //icon={<FolderOutlined />}
                                        >                                       
                                            Picture
                                        </Button>
                                        <Button className="btn-primary" type="primary" onClick={() => openSharepointFolder('GENSET', line)}>
                                            Procedure
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                    }
                    </div>                
            </div>

            <Modal
                visible={openTM}
                //title={<><span className="text-center fw-bold fs-5">ENTER QTY FOR PART# </span></>}
                destroyOnClose={true}                
                closable={false}
                centered
                width={275}
                footer={null}
                onOk={handleOkTM}
                onCancel={handleCancelTM}
                //footer={[
                //    <Button key="submit" className="rounded text-center" type="primary" loading={loadingCM} onClick={handleOkCM} style={{ background: "#28a745", borderColor: "#28a745" }}> {/**/}
                //        Save
                //    </Button>,
                //    <Button key="back" className="rounded" onClick={handleCancelCM} style={{ background: "#eca52b", borderColor: "#eca52b" }}>
                //        Exit
                //    </Button>
                //]}
            >
                <div className="text-center pb-2">
                    <span className="fw-bold fs-5">ENTER QTY FOR PART#</span> 
                    <div className="fw-bold mb-2">
                        {`${sagePart && sagePart + " : "} ${taskMin}-${taskMax}`}
                    </div>
                    <div className="mb-3 text-center">
                        <Input size="normal" placeholder="" maxLength={2}
                            name="taskQty"
                            className={`form-control d-inline-block w-50 text-center ${errorsTask.taskQty ? 'is-invalid' : ''}`}
                            value={taskQty}
                            onChange={(e) => setTaskQty(e.target.value)}                                
                            onPressEnter={(e) => handleOkTM(e)}
                        />
                        {errorsTask.taskQty && <div className="invalid-feedback">{errorsTask.taskQty}</div>}
                    </div>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <Button key="cancel" className="rounded text-center btn-primary" type="primary" onClick={handleCancelTM} style={{ width: "48%" }}> 
                            Cancel
                        </Button>
                        <Button key="submit" className="rounded text-center btn-success" type="primary" loading={loadingTM} onClick={handleOkTM} style={{ width: "48%" }}> {/*style={{ background: "#28a745", borderColor: "#28a745" }}*/}
                            Save
                        </Button>                        
                    </div>
                </div>                    
            </Modal>
        </>
    );
};

export default Pti