import React, { useEffect, useContext, useState } from "react";
import { Select, DatePicker, Button, Input, Modal, Form as FormAntd, Row, Col, Divider } from 'antd';
import 'antd/dist/antd.css';
import 'font-awesome/css/font-awesome.min.css';
import EditableCell from './generic/EditableCell';
import styled from "styled-components";
import SortableTable from './generic/SortableTable';
import moment from "moment";
import { connect } from 'react-redux';
import * as actions from '../actions/monitoringAction';
import huntContext from "../context/huntContext";
import MonitoringServices from "../services/monitoring-services";
import "./Monitoring.css";

const Monitoring = (props) => {
    const context = useContext(huntContext);

    // State variables
    const [filterValue, setFilterValue] = useState('');
    const [locFromFilter, setLocFromFilter] = useState('');
    const [locToFilter, setLocToFilter] = useState('');
    const [refresh, setRefresh] = useState(null);
    const [recordStateBeforeSave, setRecordStateBeforeSave] = useState(null);

    const setOriginalRecord = (rec) => {
        setRecordStateBeforeSave({ ...rec });
    };

    const modalInitialState = {
        visible: false,
        isValid: false,
        hasWarning: false,
        extraInfo: "",
        equip: null,
        errorMessage: "",
    }

    const [modalState, setModalState] = useState(modalInitialState);
    /*const [modalEquipToFind, setModalEquipToFind] = useState(null);*/

    console.log("filterValue:", filterValue);

    // Event handlers
    const handleDateChange = (value) => {
        props.changeSelectedDate(value == null ? moment() : value);
    };

    const handleFilterChange = (value) => {
        props.changeSelectedOption(value);
    };

    const handleShowMissing = () => {
        setFilterValue('');
        setLocFromFilter('');
        setLocToFilter('');

        props.getData(props.selectedDate, props.selectedOption, locFromFilter, locToFilter, filterValue, true);
    };

    const handleAdd = () => {
        setModalState({ ...modalState, visible: true });
    }

    const handleModalFindEquipment = (value) => {
        if (value)
            MonitoringServices.findEquipment(value, props.selectedOption, (response) => {
                if (response.data.hasWarning) {
                    window.alert(response.data.extraInfo);
                }

                setModalState({
                    ...modalState,
                    equip: response.data.equipment,
                    errorMessage: response.data.errorMessage,
                    isValid: response.data.isValid,
                    hasWarning: response.data.hasWarning,
                    extraInfo: response.data.extraInfo
                });
            });
        else {
            setModalState({
                ...modalState,
                equip: null,
                hasWarning: false,
                extraInfo: "",
                errorMessage: "Equipment number cannot be blank!",
                isValid: false
            });
        }
    }

    const handleModalAdd = () => {
        var rec = {
            cntr_num: modalState.equip.containerNumber,
            line: modalState.equip.line,
            terminal: modalState.equip.terminal,
            active: "T",
            life_num: modalState.equip.lifeNum,
            size: modalState.equip.size,
            type: modalState.equip.type,
            height: modalState.equip.height,
            location: modalState.equip.location,
            loaded: false,
            added: true,
            set_point: modalState.equip.setPoint,
            int_num: modalState.equip.intNum,
            temper_am_day1: null,
            temper_pm_day1: null,
            temper_am_day2: null,
            temper_pm_day2: null,
            temper_am_day3: null,
            temper_pm_day3: null,
            temper_am_day4: null,
            temper_pm_day4: null,
        };
        props.saveRow(props.selectedDate, rec, null);
        setModalState(modalInitialState);
        setRefresh(Date.now());
    }

    const handleModalCancel = () => {
        setModalState(modalInitialState);
    }

    const handleSave = (record) => {
        console.log(record);
        props.saveRow(props.selectedDate, record, recordStateBeforeSave);
        setOriginalRecord(null)
    };


    useEffect(() => {
        var selectedOption = context.terminals ? context.terminals[0] : 'ND';
        props.getData(moment(), selectedOption, locFromFilter, locToFilter, filterValue, false);
    }, []);

    useEffect(() => {
        if (!refresh)
            return;

        props.getData(props.selectedDate, props.selectedOption, locFromFilter, locToFilter, filterValue, false);
    }, [refresh]);

    // Render columns
    const getRenderForColumns = () => {
        if (props.columns) {
            return props.columns.map((col, ind) => {
                let newCol = { ...col };

                if (col.key.startsWith('temper_day')) {
                    // Date columns
                    newCol.children = col.children.map((subCol) => {
                        // AM PM columns
                        subCol.render = (_, record) => (
                            <EditableCell
                                record={record}
                                field={subCol.key}
                                id={record.id}
                                key={'key' + record.id + record[col.key] + subCol.key}
                                update={props.updateRowData}
                                onEditStart={() => setOriginalRecord(record)} // Capture the original record before editing
                                onEditFinish={() => handleSave(record)}
                                isNumeric={true}
                                isModifiable={
                                    moment(col.title).startOf('day').diff(moment(Date.now()).startOf('day'), 'days') >= 0 && record.active === 'T'
                                }
                            />
                        );
                        return subCol;
                    });
                }

                if (col && col.key === 'btn') {
                    newCol.render = (_, record) => (
                        (props['isSavingRow' + record.id] === false || props['isSavingRow' + record.id] === undefined) ? (
                            <button
                                style={{
                                    backgroundColor: 'lightgreen',
                                    borderRadius: '10px',
                                    borderWidth: '2px'
                                }}
                                onClick={() => handleSave(record)}
                            >
                                <i className="fa fa-check" style={{ fontSize: '14px', color: 'white' }}></i>
                            </button>
                        ) : (
                            <i className="fa fa-spinner fa-spin" style={{ fontSize: '14px', color: 'black' }}></i>
                        )
                    );
                }

                return newCol;
            });
        }
        return [];
    };

    const render = () => {
        return (
            <>
                {props.isLoadingData === false && (
                    <AntdWrapper>
                        <div style={{ marginBottom: 16 }}>
                            <div>
                                <table>
                                    <thead>
                                        <tr>
                                            <td>
                                                <span style={{ marginRight: '5px' }}>Terminal</span>
                                                <Select
                                                    options={context.terminals.map(x => ({ value: x, label: x }))}
                                                    value={props.selectedOption}
                                                    style={{ marginRight: 8, width: '100px' }}
                                                    onChange={handleFilterChange}
                                                />
                                            </td>
                                            <div style={{ width: '260px', border: '1px solid #D9D9D9', display: 'flex', justifyContent: 'space-between', height: 33 }}>
                                                <Input
                                                    placeholder="LOCATION FROM ____"
                                                    style={{ marginRight: 8, width: '230px', borderRadius: 0, borderWidth: 0, fontSize: 'small' }}
                                                    value={locFromFilter}
                                                    onChange={(e) => setLocFromFilter(e.target.value.toUpperCase())}
                                                />
                                                <Input
                                                    placeholder="TO ____"
                                                    style={{ marginRight: 8, width: '140px', borderRadius: 0, borderWidth: 0, fontSize: 'small' }}
                                                    value={locToFilter}
                                                    onChange={(e) => setLocToFilter(e.target.value.toUpperCase())}
                                                />
                                            </div>
                                            <td>
                                                <Input
                                                    placeholder="Equip _______"
                                                    value={filterValue}
                                                    onChange={(e) => setFilterValue(e.target.value.toUpperCase())}
                                                    style={{ marginRight: 8, width: '120px', borderRadius: 0, border: '1px solid #D9D9D9', fontSize: 'small' }}
                                                />
                                            </td>
                                            <td>
                                                <span style={{ marginRight: '5px' }}>Beginning date</span>
                                                <DatePicker onChange={handleDateChange} value={moment(props.selectedDate)} style={{ marginRight: 8 }} />
                                            </td>
                                            <td>
                                                <Button onClick={() => setRefresh(Date.now())}>Refresh</Button>
                                            </td>
                                            <td>
                                                <Button onClick={handleShowMissing}>Show missing</Button>
                                            </td>
                                            <td>
                                                <Button onClick={handleAdd}>Add</Button>
                                            </td>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                        <SortableTable columns={getRenderForColumns()} isSort={true} data={props.data} rowKey={'id'} />
                    </AntdWrapper>
                )}
                {props.isLoadingData === true && (
                    <div className="border border-5 border-secondary  overflow" style={{ borderColor: 'gray', marginTop: '20px', marginLeft: '40%', width: '120px', height: '90px', textAlign: 'center', fontSize: '32px' }}>
                        <i className="fa fa-refresh fa-spin" aria-hidden="true" style={{ fontSize: '30px', paddingTop: '10px', paddingBottom: '10px' }}></i>
                        <div style={{ fontSize: '14px' }}>Loading data</div>
                    </div>
                )}

                <Modal style={{ fontSize: '2em' }} width={480} visible={modalState.visible} okText="Add"
                    okButtonProps={{ shape: "round", disabled: !modalState.isValid }}
                    cancelButtonProps={{ shape: "round" }}
                    onCancel={handleModalCancel}
                    onOk={handleModalAdd}
                    destroyOnClose={true} closable={false}>
                    <FormAntd layout="vertical" preserve={false}>
                        <Row>
                            Enter the equipment number to add to the list:
                        </Row>
                        <Divider />
                        <Row>
                            <Col span={24}>
                                <FormAntd.Item autoFocus={true} name="Equipment" validateStatus={modalState.errorMessage !== null ? 'error' : 'success'} help={modalState.errorMessage} >
                                    <Input.Search autoFocus={true} name="modalEquipToFind" allowClear
                                        onSearch={(value) => handleModalFindEquipment(value)} />
                                </FormAntd.Item>
                            </Col>
                        </Row>
                    </FormAntd>
                </Modal >

            </>
        );
    };

    return render();
};

const mapStateToProps = state => {
    let p = {};
    let keys = Object.keys(state.monitoringReducer);

    keys.forEach(key => p[key] = state.monitoringReducer[key]);

    return p;
};

const mapDispatchToProps = dispatch => {
    return {
        getData: (selectedDate, selectedOption, locFromFilter, locToFilter, filterValue, showMissing) => dispatch(actions.loadData(selectedDate, selectedOption, locFromFilter, locToFilter, filterValue, showMissing)),
        updateRowData: (field, id, value) => dispatch(actions.updateValueField(field, id, value)),
        changeSelectedDate: (value) => dispatch(actions.changeSelectedDate(value)),
        changeSelectedOption: (value) => dispatch(actions.changeSelectedOption(value)),
        saveRow: (selectedDate, record, originalRecord) => dispatch(actions.saveData(selectedDate, record, originalRecord))
    };
};

const AntdWrapper = styled.div`
  .ant {
    & * {
      box-sizing: border-box;
    }
    &-row > &-col {
      padding: 0;
    }
  }
`;

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Monitoring);
